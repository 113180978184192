import MainLayout from "layout/MainLayout";
import {
  Dashboard,
  SalesRegister,
  SaleDetails,
  SalesDashboard,
  CostOfGoodsSoldRegister,
  PaymentSummaryReport,
  PaymentRequestForm,
  ConsolidateSalesReport,
  ChannelSalesReport,
  ConsolidateCostOfGoodsSoldReport,
  UsersRegister,
  UserDetails,
  RolesRegister,
  RoleDetails,
  StoresRegister,
  StoreDetails,
  WorkflowsRegister,
  WorkflowDetails,
  WorkflowStates,
  WorkflowStateDetails,
  WorkflowActions,
  WorkflowActionDetails,
  ErrorPage,
  NotFound,
  AllDesginations,
  AllDepartments,
  DesignationDetail,
  ItemGroup,
  ItemGroupDetails,
  ItemUnitOfMeasurement,
  UnitOfMeasurementDetails,
  UnitOfMeasurementCategory,
  ItemUomCategoryDeatils,
  UnitOfMeasurementFactor,
  UnitOfMeasurementFactorDetails,
  PulseCategory,
  PulseCategoryDetails,
  ItemGroupLevel,
  ItemGroupLevelDetails,
  Items,
  ItemDetails,
  PulseConsolidateInventoryReport,
  StoreGroupsRegister,
  StoreGroupDetails,
  DepartmentDetail,
  InventoryDetails,
  AllEmployees,
  EmployeeDetail,
  MaterialRequestRegister,
  MaterialRequestDetail,
  UserProfile,
  EmployeeTimesheetDetail,
  EmployeeTimesheetRegister,
  AllShifts,
  ShiftDetails,
  AllHolidays,
  HolidayDetails,
  EmployeeRoster,
  RosterRegister,
  EmployeeTimeClock,
} from "./PageAsync";
import PropTypes from "prop-types";
import { can_perform_action } from "utils/helper";
import EmployeeMonthlyTimesheet from "views/employee_monthly_timesheet";

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute
            WrappedComponent={ConsolidateSalesReport}
            classes={classes}
            module_name="Consolidate Sales"
          />
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute
            WrappedComponent={Dashboard}
            classes={classes}
            module_name="Dashboard"
          />
        ),
      },
      {
        path: "/sales/sales-register",
        element: (
          <PrivateRoute
            WrappedComponent={SalesRegister}
            classes={classes}
            module_name="Sales Register"
          />
        ),
      },
      {
        path: "/sales/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={SaleDetails}
            classes={classes}
            module_name="Sales Register"
          />
        ),
      },
      {
        path: "/sales/dashboard",
        element: (
          <PrivateRoute
            WrappedComponent={SalesDashboard}
            classes={classes}
            module_name="Sales Register"
          />
        ),
      },
      {
        path: "/inventory/inventory-register",
        element: (
          <PrivateRoute
            WrappedComponent={CostOfGoodsSoldRegister}
            classes={classes}
            module_name="Inventory Register"
          />
        ),
      },
      {
        path: "/inventory/inventory-details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={InventoryDetails}
            classes={classes}
            module_name="Inventory Register"
          />
        ),
      },
      {
        path: "/finance/payment-summary",
        element: (
          <PrivateRoute
            WrappedComponent={PaymentSummaryReport}
            classes={classes}
            module_name="Payment Summary"
          />
        ),
      },
      {
        path: "/finance/payment-request",
        element: (
          <PrivateRoute
            WrappedComponent={PaymentRequestForm}
            classes={classes}
            module_name="Payment Request"
          />
        ),
      },
      {
        path: "/finance/payment-request/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PaymentRequestForm}
            classes={classes}
            module_name="Payment Request"
          />
        ),
      },
      {
        path: "/finance/material-request",
        element: (
          <PrivateRoute
            WrappedComponent={MaterialRequestRegister}
            classes={classes}
            module_name="Material Request"
          />
        ),
      },
      {
        path: "/finance/material-request-detail",
        element: (
          <PrivateRoute
            WrappedComponent={MaterialRequestDetail}
            classes={classes}
            module_name="Material Request"
          />
        ),
      },
      {
        path: "/finance/material-request-detail/:id",
        element: (
          <PrivateRoute
            WrappedComponent={MaterialRequestDetail}
            classes={classes}
            module_name="Material Request"
          />
        ),
      },
      {
        path: "/reporting-analytics/consolidate-sales",
        element: (
          <PrivateRoute
            WrappedComponent={ConsolidateSalesReport}
            classes={classes}
            module_name="Consolidate Sales"
          />
        ),
      },
      {
        path: "/reporting-analytics/channel-sales",
        element: (
          <PrivateRoute
            WrappedComponent={ChannelSalesReport}
            classes={classes}
            module_name="Channel Sales"
          />
        ),
      },
      {
        path: "/reporting-analytics/consolidate-cogs",
        element: (
          <PrivateRoute
            WrappedComponent={ConsolidateCostOfGoodsSoldReport}
            classes={classes}
            module_name="Consolidate COGS"
          />
        ),
      },
      {
        path: "/settings/users",
        element: (
          <PrivateRoute
            WrappedComponent={UsersRegister}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/users/details",
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/users/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/roles",
        element: (
          <PrivateRoute
            WrappedComponent={RolesRegister}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/roles/details",
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/roles/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/stores",
        element: (
          <PrivateRoute
            WrappedComponent={StoresRegister}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/stores/details",
        element: (
          <PrivateRoute
            WrappedComponent={StoreDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/stores/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={StoreDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/store-groups",
        element: (
          <PrivateRoute
            WrappedComponent={StoreGroupsRegister}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/store-groups/details",
        element: (
          <PrivateRoute
            WrappedComponent={StoreGroupDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/store-groups/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={StoreGroupDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/workflow-register",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowsRegister}
            classes={classes}
            module_name="Workflow Register"
          />
        ),
      },
      {
        path: "/settings/workflow-register/details",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowDetails}
            classes={classes}
            module_name="Workflow Register"
          />
        ),
      },
      {
        path: "/settings/workflow-register/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowDetails}
            classes={classes}
            module_name="Workflow Register"
          />
        ),
      },
      {
        path: "/settings/workflow-states",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowStates}
            classes={classes}
            module_name="Workflow States"
          />
        ),
      },
      {
        path: "/settings/workflow-states/details",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowStateDetails}
            classes={classes}
            module_name="Workflow States"
          />
        ),
      },
      {
        path: "/settings/workflow-states/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowStateDetails}
            classes={classes}
            module_name="Workflow States"
          />
        ),
      },
      {
        path: "/settings/workflow-actions",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowActions}
            classes={classes}
            module_name="Workflow Actions"
          />
        ),
      },
      {
        path: "/settings/workflow-actions/details",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowActionDetails}
            classes={classes}
            module_name="Workflow Actions"
          />
        ),
      },
      {
        path: "/settings/workflow-actions/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={WorkflowActionDetails}
            classes={classes}
            module_name="Workflow Actions"
          />
        ),
      },
      {
        path: "/human-resources/employee/",
        element: (
          <PrivateRoute
            WrappedComponent={AllEmployees}
            classes={classes}
            module_name="Employee"
          />
        ),
      },
      {
        path: "/human-resources/employee/details",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeDetail}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/employee/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeDetail}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/departments/",
        element: (
          <PrivateRoute
            WrappedComponent={AllDepartments}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/departments/details",
        element: (
          <PrivateRoute
            WrappedComponent={DepartmentDetail}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/departments/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DepartmentDetail}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/designations/",
        element: (
          <PrivateRoute
            WrappedComponent={AllDesginations}
            classes={classes}
            module_name="Designations"
          />
        ),
      },
      {
        path: "/human-resources/designations/details",
        element: (
          <PrivateRoute
            WrappedComponent={DesignationDetail}
            classes={classes}
            module_name="Designations"
          />
        ),
      },
      {
        path: "/human-resources/designations/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DesignationDetail}
            classes={classes}
            module_name="Designations"
          />
        ),
      },
      {
        path: "/inventory/item-group",
        element: (
          <PrivateRoute
            WrappedComponent={ItemGroup}
            classes={classes}
            module_name="Item Group"
          />
        ),
      },
      {
        path: "/inventory/item-group/details",
        element: (
          <PrivateRoute
            WrappedComponent={ItemGroupDetails}
            classes={classes}
            module_name="Item Group"
          />
        ),
      },
      {
        path: "/inventory/item-group/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ItemGroupDetails}
            classes={classes}
            module_name="Item Group"
          />
        ),
      },
      {
        path: "/inventory/unit-of-measurement",
        element: (
          <PrivateRoute
            WrappedComponent={ItemUnitOfMeasurement}
            classes={classes}
            module_name="Unit of Measurement"
          />
        ),
      },
      {
        path: "/inventory/unit-of-measurement/details",
        element: (
          <PrivateRoute
            WrappedComponent={UnitOfMeasurementDetails}
            classes={classes}
            module_name="Unit of Measurement"
          />
        ),
      },
      {
        path: "/inventory/unit-of-measurement/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={UnitOfMeasurementDetails}
            classes={classes}
            module_name="Unit of Measurement"
          />
        ),
      },
      {
        path: "/inventory/uom-category",
        element: (
          <PrivateRoute
            WrappedComponent={UnitOfMeasurementCategory}
            classes={classes}
            module_name="UoM Category"
          />
        ),
      },
      {
        path: "/inventory/uom-category/details",
        element: (
          <PrivateRoute
            WrappedComponent={ItemUomCategoryDeatils}
            classes={classes}
            module_name="UoM Category"
          />
        ),
      },
      {
        path: "/inventory/uom-category/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ItemUomCategoryDeatils}
            classes={classes}
            module_name="UoM Category"
          />
        ),
      },
      {
        path: "/inventory/uom-factors",
        element: (
          <PrivateRoute
            WrappedComponent={UnitOfMeasurementFactor}
            classes={classes}
            module_name="UoM Factors"
          />
        ),
      },
      {
        path: "/inventory/uom-factors/details",
        element: (
          <PrivateRoute
            WrappedComponent={UnitOfMeasurementFactorDetails}
            classes={classes}
            module_name="UoM Factors"
          />
        ),
      },
      {
        path: "/inventory/uom-factors/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={UnitOfMeasurementFactorDetails}
            classes={classes}
            module_name="UoM Factors"
          />
        ),
      },
      {
        path: "/inventory/pulse-category",
        element: (
          <PrivateRoute
            WrappedComponent={PulseCategory}
            classes={classes}
            module_name="Pulse Category"
          />
        ),
      },
      {
        path: "/inventory/pulse-category/details",
        element: (
          <PrivateRoute
            WrappedComponent={PulseCategoryDetails}
            classes={classes}
            module_name="Pulse Category"
          />
        ),
      },
      {
        path: "/inventory/pulse-category/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={PulseCategoryDetails}
            classes={classes}
            module_name="Pulse Category"
          />
        ),
      },
      {
        path: "/inventory/item-approval-level",
        element: (
          <PrivateRoute
            WrappedComponent={ItemGroupLevel}
            classes={classes}
            module_name="Item Approval Level"
          />
        ),
      },
      {
        path: "/inventory/item-approval-level/details",
        element: (
          <PrivateRoute
            WrappedComponent={ItemGroupLevelDetails}
            classes={classes}
            module_name="Item Approval Level"
          />
        ),
      },
      {
        path: "/inventory/item-approval-level/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ItemGroupLevelDetails}
            classes={classes}
            module_name="Item Approval Level"
          />
        ),
      },
      {
        path: "/inventory/items",
        element: (
          <PrivateRoute
            WrappedComponent={Items}
            classes={classes}
            module_name="Items"
          />
        ),
      },
      {
        path: "/inventory/items/details",
        element: (
          <PrivateRoute
            WrappedComponent={ItemDetails}
            classes={classes}
            module_name="Items"
          />
        ),
      },
      {
        path: "/inventory/items/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ItemDetails}
            classes={classes}
            module_name="Items"
          />
        ),
      },
      {
        path: "/reporting-analytics/consolidate-pulse-inventory",
        element: (
          <PrivateRoute
            WrappedComponent={PulseConsolidateInventoryReport}
            classes={classes}
            module_name="Consolidate Pulse Inventory"
          />
        ),
      },
      {
        path: "/human-resources/attendence",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeTimesheetRegister}
            classes={classes}
            module_name="Attendence"
          />
        ),
      },
      {
        path: "/human-resources/shifts",
        element: (
          <PrivateRoute
            WrappedComponent={AllShifts}
            classes={classes}
            module_name="Shifts"
          />
        ),
      },
      {
        path: "/human-resources/holidays",
        element: (
          <PrivateRoute
            WrappedComponent={AllHolidays}
            classes={classes}
            module_name="Holidays"
          />
        ),
      },
      {
        path: "/human-resources/shifts/details",
        element: (
          <PrivateRoute
            WrappedComponent={ShiftDetails}
            classes={classes}
            module_name="Shifts"
          />
        ),
      },
      {
        path: "/human-resources/shifts/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ShiftDetails}
            classes={classes}
            module_name="Shifts"
          />
        ),
      },
      {
        path: "/human-resources/holidays/details",
        element: (
          <PrivateRoute
            WrappedComponent={HolidayDetails}
            classes={classes}
            module_name="Holidays"
          />
        ),
      },
      {
        path: "/human-resources/holidays/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={HolidayDetails}
            classes={classes}
            module_name="Holidays"
          />
        ),
      },
      {
        path: "/human-resources/weekly-schedule/details",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeRoster}
            classes={classes}
            module_name="Weekly Schedule"
          />
        ),
      },
      {
        path: "/human-resources/weekly-schedule/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeRoster}
            classes={classes}
            module_name="Weekly Schedule"
          />
        ),
      },
      {
        path: "/human-resources/weekly-schedule",
        element: (
          <PrivateRoute
            WrappedComponent={RosterRegister}
            classes={classes}
            module_name="Weekly Schedule"
          />
        ),
      },
      {
        path: "/human-resources/attendence/detail/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeTimesheetDetail}
            classes={classes}
            module_name="Attendence"
          />
        ),
      },
      {
        path: "/clock",
        element: <EmployeeTimeClock classes={classes} />,
      },
      {
        path: "/user-profile",
        element: <UserProfile classes={classes} />,
      },
      {
        path: "/human-resources/timesheet",
        element: <EmployeeMonthlyTimesheet classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };

  return MainRoutes;
}

const PrivateRoute = (props: any) => {
  const { WrappedComponent, classes, module_name } = props;
  if (can_perform_action(module_name)) {
    return <WrappedComponent classes={classes} module_name={module_name} />;
  } else {
    return <ErrorPage classes={classes} />;
  }
};

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
