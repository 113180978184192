import React from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CalendarTodayOutlined } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";

export default function DatePickerNew(props: any) {
  const {
    classes,
    input_label,
    required,
    value,
    error,
    textFeildClasses,
    disabled,
    height,
    placeholder,
    readOnly,
    handleDateChange,
    format,
    disablePast,
    minDate,
    maxDate,
  } = props;
  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {input_label && (
        <label
          className={classNames(
            classes.white_input_label_style,
            "RobotoCondensed-Regular",
            error && classes.color_red
          )}
          style={{
            padding: "4px 0px 4px 10px",
            fontFamily: "BebasNeue-Book",
            fontSize: 16,
            fontWeight: 900,
            lineHeight: "normal",
            letterSpacing: "1px",
            color: "#000000",
            textTransform: "uppercase",
          }}
        >
          {input_label}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}

      <MobileDatePicker
        // value={value}
        value={value ? moment(value) : null}
        format={typeof format === "string" ? format : "DD.MMM.YYYY HH:mm:ss"}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#181818",
          },
        }}
        slotProps={{
          textField: {
            error: error,
            style: {
              background: "white",
            },
            placeholder,
            inputProps: {
              className: textFeildClasses,
              style: {
                height: 0,
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "normal",
                color: "#181818",
                background: "white",
              },
            },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end" style={{ background: "white" }}>
                  <CalendarTodayOutlined style={{ fontSize: 24 }} />
                </InputAdornment>
              ),
              style: {
                height,
                background: "white",
              },
              readOnly,
            },
          },
        }}
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleDateChange}
      />
    </form>
  );
}

DatePickerNew.defaultProps = {
  value: null,
  input_label: "",
  fullWidth: true,
  required: false,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  textFeildClasses: "",
  disabled: false,
  height: 45,
  placeholder: "",
  readOnly: false,
  handleDateChange: () => {},
  format: "DD-MMM-YYYY",
  disablePast: false,
  minDate: null,
  maxDate : null,
};

DatePickerNew.propTypes = {
  input_label: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  classes: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.string,
  variant: PropTypes.string,
  textFeildClasses: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  handleDateChange: PropTypes.func,
  format: PropTypes.string,
  disablePast: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate : PropTypes.any,
};
