import React from "react";
import {
  Document,
  Page,
  View,
  Image,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";
import dominos_logo from "../../../src/assets/images/logo/dominos-blue-logo.png";
import moment from "moment";

Font.register({
  family: "Roboto",
  src: require("../../assets/fonts/Roboto/Roboto-Regular.ttf"),
});

Font.register({
  family: "Roboto-Condensed",
  src: require("../../assets/fonts/Roboto/Roboto-Condensed-Regular.ttf"),
});

Font.register({
  family: "RobotoCondensed-Bold",
  src: require("../../assets/fonts/Roboto/Roboto-Condensed-Bold.ttf"),
});

Font.register({
  family: "RobotoCondensed-Medium",
  src: require("../../assets/fonts/Roboto/Roboto-Condensed-Medium.ttf"),
});

Font.register({
  family: "BebasNeue-Bold",
  src: require("../../assets/fonts/BebasNeue/BebasNeue-Bold.otf"),
});

const styles = StyleSheet.create({
  body: {
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  title: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 18,
    color: "#000000",
    marginTop: 5,
    fontFamily: "RobotoCondensed-Bold",
  },
  panel_head: {
    fontSize: 12,
    color: "#000000",
    padding: "2px 0px 2px 0px",
    fontFamily: "RobotoCondensed-Bold",
  },
  detail_text: {
    margin: "0px 0px",
    fontSize: 10,
    color: "#000000",
    fontFamily: "Roboto-Condensed",
  },
  section: {
    flexGrow: 1,
  },
});

function EmployeeMonthlyTimesheetPDF(props: any) {
  return props?.data?.length > 0 ? (
    <Document title="Employee Timesheet">
      {props?.data?.map((item: any) => {
        return (
          <Page style={styles.body} size={"A4"}>
            <View fixed>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Image
                  src={dominos_logo}
                  style={{ width: "160px", height: "30px" }}
                />
              </View>
            </View>

            <View
              style={{
                flexDirection: "column",
                marginTop: 10,
                backgroundColor: "#d2112b",
                borderTop: "1px solid #a4a4a4",
                borderLeft: "1px solid #a4a4a4",
                borderRight: "1px solid #a4a4a4",
                borderBottom: "1px solid #a4a4a4",
                color: "white",
              }}
            >
              <Text
                style={{
                  ...styles.title,
                  fontSize: 18,
                  width: "100%",
                  textAlign: "center",
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontFamily: "BebasNeue-Bold",
                }}
              >
                {"Employee Timesheet"}
              </Text>

              <Text
                style={{
                  ...styles.title,
                  marginTop: 5,
                  fontSize: 16,
                  width: "100%",
                  textAlign: "center",
                  color: "#ffffff",
                  textTransform: "uppercase",
                  fontFamily: "BebasNeue-Bold",
                }}
              >
                {props?.start_date && props?.end_date
                  ? moment(props?.start_date).format("dddd, DD.MMMM.YYYY") +
                    " - " +
                    moment(props?.end_date).format("dddd, DD.MMMM.YYYY")
                  : "Not Available"}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "column",
                marginTop: 10,
                borderLeft: "1px solid #a4a4a4",
                borderRight: "1px solid #a4a4a4",
                borderBottom: "1px solid #a4a4a4",
                backgroundColor: "#005177",
                color: "#ffffff",
                fontFamily: "BebasNeue-Bold",
                fontSize: 16,
              }}
            >
              <Text
                style={{
                  ...styles.title,
                  width: "100%",
                  textAlign: "center",
                  color: "#ffffff",
                  fontFamily: "BebasNeue-Bold",
                  textTransform: "uppercase",
                }}
              >
                {item?.employee_code + " - " + item?.emp_full_name ??
                  "Not Available"}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "column",
                borderLeft: "1px solid #a4a4a4",
                borderRight: "1px solid #a4a4a4",
                borderBottom: "1px solid #a4a4a4",
                backgroundColor: "#005177",
                color: "#ffffff",
                marginBottom: 10,
                fontFamily: "BebasNeue-Bold",
              }}
            >
              <Text
                style={{
                  ...styles.title,
                  fontSize: 14,
                  width: "100%",
                  textAlign: "center",
                  color: "#ffffff",
                  fontFamily: "BebasNeue-Bold",
                  textTransform: "uppercase",
                }}
              >
                {item?.store_name ?? ""} - {item?.department_name ?? ""} -{" "}
                {item?.designation_name ?? ""}
              </Text>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                  borderLeft: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Date{" "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Day
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Clock In{" "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Clock Out{" "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Actual Hours{" "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    Expected Hours{" "}
                  </Text>
                </View>
              </View>
            </View>

            {item?.time_data?.map((itemx: any) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      width: "50%",
                      padding: "0px 0px 0px 10px",
                      borderRight: "1px solid #a4a4a4",
                      borderBottom: "1px solid #a4a4a4",
                      borderLeft: "1px solid #a4a4a4",
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingTop: 3 }}>
                      <Text
                        style={{
                          ...styles.detail_text,
                        }}
                      >
                        {moment(itemx?.dateeee).format("DD.MMMM.YYYY")}{" "}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "50%",
                      padding: "0px 0px 0px 10px",
                      borderRight: "1px solid #a4a4a4",
                      borderBottom: "1px solid #a4a4a4",
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingTop: 3 }}>
                      <Text style={{ ...styles.detail_text }}>
                        {moment(itemx?.dateeee).format("dddd")}{" "}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "50%",
                      padding: "0px 0px 0px 10px",
                      borderRight: "1px solid #a4a4a4",
                      borderBottom: "1px solid #a4a4a4",
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingTop: 3 }}>
                      <Text
                        style={{
                          ...styles.detail_text,
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {itemx?.start_time === null
                          ? "--:--"
                          : moment(
                              "2024-01-01T" +
                                itemx?.start_time?.substring(0, 5) +
                                ":00Z"
                            )
                              .utcOffset("+04:00")
                              .format("HH:mm")}{" "}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "50%",
                      padding: "0px 0px 0px 10px",
                      borderRight: "1px solid #a4a4a4",
                      borderBottom: "1px solid #a4a4a4",
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingTop: 3 }}>
                      <Text
                        style={{
                          ...styles.detail_text,
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {itemx?.end_time === null
                          ? "--:--"
                          : moment(
                              "2024-01-01T" +
                                itemx?.end_time?.substring(0, 5) +
                                ":00Z"
                            )
                              .utcOffset("+04:00")
                              .format("HH:mm")}{" "}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "50%",
                      padding: "0px 0px 0px 10px",
                      borderRight: "1px solid #a4a4a4",
                      borderBottom: "1px solid #a4a4a4",
                    }}
                  >
                    <View style={{ flexDirection: "row", paddingTop: 3 }}>
                      <Text
                        style={{
                          ...styles.detail_text,
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {itemx?.duration === null
                          ? "--:--"
                          : itemx?.duration?.substring(0, 5)}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={
                      itemx?.expected_days_off?.toLowerCase() === "leave"
                        ? {
                            width: "50%",
                            padding: "0px 0px 0px 10px",
                            borderRight: "1px solid #a4a4a4",
                            borderBottom: "1px solid #a4a4a4",
                            backgroundColor: "chocolate",
                          }
                        : itemx?.expected_days_off?.toLowerCase() === "off"
                        ? {
                            width: "50%",
                            padding: "0px 0px 0px 10px",
                            borderRight: "1px solid #a4a4a4",
                            borderBottom: "1px solid #a4a4a4",
                            backgroundColor: "#f44336",
                          }
                        : itemx?.expected_days_off?.toLowerCase() === "holiday"
                        ? {
                            width: "50%",
                            padding: "0px 0px 0px 10px",
                            borderRight: "1px solid #a4a4a4",
                            borderBottom: "1px solid #a4a4a4",
                            backgroundColor: "blueviolet",
                          }
                        : {
                            width: "50%",
                            padding: "0px 0px 0px 10px",
                            borderRight: "1px solid #a4a4a4",
                            borderBottom: "1px solid #a4a4a4",
                          }
                    }
                  >
                    <View style={{ flexDirection: "row", paddingTop: 3 }}>
                      <Text
                        style={
                          itemx?.expected_days_off?.toLowerCase() ===
                              "leave" || itemx?.expected_days_off?.toLowerCase() ===
                              "off" || itemx?.expected_days_off?.toLowerCase() ===
                              "holiday"
                            ? {
                                ...styles.detail_text,
                                color: "#fff",
                                textAlign: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }
                            : {
                                ...styles.detail_text,
                                textAlign: "center",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }
                        }
                      >
                        {itemx?.expected_days_off?.toLowerCase() ===
                          "present" || itemx?.expected_days_off === null
                          ? itemx?.expected_hours === null
                            ? "--:--"
                            : itemx?.expected_hours?.substring(0, 5)
                          : itemx?.expected_days_off}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}

            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderLeft: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                    }}
                  >
                    Expected Hours{" "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderTop: "1px solid #a4a4a4",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {item?.expected_hours === null
                      ? "--:--"
                      : item?.expected_hours?.substring(0, 5)}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                }}
              ></View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderRight: "1px solid #a4a4a4",
                  borderLeft: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontWeight: "bold",
                      fontFamily: "RobotoCondensed-Bold",
                    }}
                  >
                    Actual Hours{" "}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  padding: "0px 0px 0px 10px",
                  borderRight: "1px solid #a4a4a4",
                  borderBottom: "1px solid #a4a4a4",
                }}
              >
                <View style={{ flexDirection: "row", paddingTop: 3 }}>
                  <Text
                    style={{
                      ...styles.detail_text,
                      textAlign: "center",
                      fontFamily: "RobotoCondensed-Bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {item?.actual_hours === null
                      ? "--:--"
                      : item?.actual_hours?.substring(0, 5)}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  ) : null;
}

export default EmployeeMonthlyTimesheetPDF;
