import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSubTitle, setTitle } from "store/breadcrumbReducer";
import LoadingComponent from "ui-components/LoadingComponent";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  convert_utc_to_user_timezone,
  remove_null_keys,
  set_localize_content,
} from "utils/helper";
import {
  datatable_translation,
  employee_timesheet_errors_translation,
  employee_timesheet_filters_translation,
  employee_timesheet_translation,
  reusable_translation,
  search_filters_translation,
} from "../../translation";
import moment from "moment";
import { getDataFromLocalStorage } from "utils/localStore.utils";
import {
  employee_monthly_timesheet,
  export_employee_monthly_timesheet,
  handle_clear_form,
} from "store/timesheetReducer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Button from "ui-components/Button";
import classNames from "classnames";
import {
  Delete,
  ExpandMore,
  FileDownload,
  PictureAsPdf,
  Search,
} from "@mui/icons-material";
import DateRangeFilter from "ui-components/DateRangeFilter";
import DatePickerNew from "ui-components/DatePickerNew";
import SelectField from "ui-components/SelectField";
import { employee_locations } from "store/employeeReducer";
import { all_designations } from "store/designationsReducer";
import { all_departments } from "store/departmentsReducer";
import { toast } from "react-toastify";
import { create_logger } from "store/loggerReducer";
import { export_label, print_label } from "utils/constants";
import { utils, writeFile } from "xlsx";
import EmployeeMonthlyTimesheetPDF from "views/employee_monthly_timesheet_pdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ItemTable from "ui-components/ItemDatatable";
import DraggableDialog from "ui-components/DraggableDialog";

export default function EmployeeMonthlyTimesheet(props: any) {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      timesheet_date_checked: true,
      start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
      end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      date_range: {
        label: set_localize_content(
          search_filters_translation?.this_week,
          "en"
        ),
        value: "this_week",
      },

      employee_name_checked: false,
      employee_name: "",

      employee_code_checked: false,
      employee_code: "",

      department_checked: false,
      department: null,
      departments_list: [],
      department_codes: [],

      designation_checked: false,
      designation: null,
      designations_list: [],

      location_checked: false,
      location: null,
      locations_list: [],
      location_codes: [],

      emp_timesheet_records: [],
      attendence_record: [],
      emp_index: null,

      searching: "",
      export_file: false,
      expand_panel: true,
      is_dialog_open: false,

      page: 1,
      limit: 10,
    }
  );

  const { classes } = props;
  const department_slicer = useSelector(
    (state: RootState) => state.departments
  );

  const designation_slicer = useSelector(
    (state: RootState) => state.designations
  );

  const employee_slicer = useSelector((state: RootState) => state.employees);
  const timesheet_slicer = useSelector((state: RootState) => state.timesheet);
  const dispatch = useDispatch<any>();
  const user = getDataFromLocalStorage("user");
  const permissions = user?.permissions?.filter((item: any) => {
    return item?.module_name?.toLowerCase() === "timesheet";
  })[0];

  const get_breadcrumbs = () => {
    try {
      dispatch(
        setTitle(
          set_localize_content(
            employee_timesheet_translation?.employee_timesheet_heading,
            "en"
          ) as any
        )
      );

      dispatch(setSubTitle("" as any));
    } catch (e) {
      console.log(e);
    }
  };

  const get_all_departments = () => {
    try {
      dispatch(
        all_departments({
          end_point: "/departments/all",
          body: {},
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const get_all_designations = () => {
    try {
      dispatch(
        all_designations({
          end_point: "/designations/all",
          body: {},
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const get_all_locations = () => {
    try {
      dispatch(
        employee_locations({
          end_point: "/locations",
          body: {},
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handle_monthly_timesheet_search_checkboxes = async (e: any) => {
    if (e.target.name === "timesheet_date_checked") {
      setState({
        ...state,
        timesheet_date_checked: !state.timesheet_date_checked,
      });
    }

    if (e.target.name === "employee_name_checked") {
      setState({
        ...state,
        employee_name_checked: !state.employee_name_checked,
        employee_name: null,
      });
    }

    if (e.target.name === "employee_code_checked") {
      setState({
        ...state,
        employee_code_checked: !state.employee_code_checked,
        employee_code: null,
      });
    }

    if (e.target.name === "department_checked") {
      setState({
        ...state,
        department_checked: !state.department_checked,
        department: null,
      });
    }

    if (e.target.name === "designation_checked") {
      setState({
        ...state,
        designation_checked: !state.designation_checked,
        designation: null,
      });
    }

    if (e.target.name === "location_checked") {
      setState({
        ...state,
        location_checked: !state.location_checked,
        locations: [],
      });
    }
  };

  const handle_input_change = async (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      isSearching: true,
    });
  };

  const handle_date_picker = (newValue: any, name: string) => {
    setState({
      [name]: moment(new Date(newValue)).format("YYYY-MM-DD"),
      date_range: {
        label: set_localize_content(
          search_filters_translation?.custom_date,
          "en"
        ),
        value: "custom date",
      },
    });
  };

  const handle_filter_date = (start_date: any) => {
    try {
      setState({
        start_date: moment(new Date(start_date.start_date)).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(new Date(start_date.end_date)).format("YYYY-MM-DD"),
        date_range: start_date?.date_range,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handle_collapse_panel = (panel: boolean) => {
    setState({
      expand_panel: panel,
    });
  };

  const get_timesheet_search_filters = async () => {
    try {
      const {
        timesheet_date_checked,
        start_date,
        end_date,
        employee_code,
        employee_name,
        department,
        department_codes,
        designation,
        location,
        location_codes,
        page,
        limit,
      } = state;

      if (
        !start_date &&
        !end_date &&
        !employee_code &&
        !employee_name &&
        !department &&
        !designation &&
        !location
      ) {
        toast.error(
          set_localize_content(
            employee_timesheet_errors_translation?.search_criteria_not_select,
            "en"
          )
        );
      } else if (!timesheet_date_checked) {
        toast.error(
          set_localize_content(
            employee_timesheet_errors_translation?.timesheet_date_required,
            "en"
          )
        );
      } else {
        let data = {
          start_date: start_date,
          end_date: end_date,
          employee_code: employee_code ? employee_code : null,
          employee_name: employee_name ? employee_name : null,
          departments:
            department !== null
              ? [Number(department?.value)]
              : department_codes,
          designations:
            designation !== null ? [Number(designation?.value)] : null,
          store_code:
            location !== null ? [Number(location?.value)] : location_codes,
          page: page,
          limit: limit,
        };

        remove_null_keys(data);

        dispatch(
          employee_monthly_timesheet({
            body: data,
            end_point: "/time-entries/get-time-sheet-dwmy",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_timesheet_by_filter = async (
    start_date: any,
    end_date: any,
    page_number: any,
    limit: any
  ): Promise<void> => {
    try {
      if (start_date && end_date) {
        const {
          start_date,
          end_date,
          employee_code,
          employee_name,
          department,
          department_codes,
          designation,
          location,
          location_codes,
        } = state;

        let data = {
          start_date: start_date,
          end_date: end_date,
          employee_code: employee_code ? employee_code : null,
          employee_name: employee_name ? employee_name : null,
          departments:
            department !== null
              ? [Number(department?.value)]
              : department_codes,
          designations:
            designation !== null ? [Number(designation?.value)] : null,
          store_code:
            location !== null ? [Number(location?.value)] : location_codes,
          page: page_number,
          limit: limit,
        };

        remove_null_keys(data);

        setState({
          start_date: start_date,
          end_date: end_date,
          page: page_number,
          limit: limit,
        });

        dispatch(
          employee_monthly_timesheet({
            body: data,
            end_point: "/time-entries/get-time-sheet-dwmy",
          })
        );
      } else {
        setState({
          page: page_number,
          limit: limit,
        });

        dispatch(
          employee_monthly_timesheet({
            body: {
              page: page_number,
              limit: limit,
            },
            end_point: "/time-entries/get-time-sheet-dwmy",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clear_all_filters = async () => {
    try {
      setState({
        timesheet_date_checked: true,
        start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),

        employee_name_checked: false,
        employee_name: "",

        employee_code_checked: false,
        employee_code: "",

        department_checked: false,
        department: null,

        designation_checked: false,
        designation: null,
        designations_list: [],

        location_checked: false,
        location: null,

        searching: "",
        export_file: false,
      });

      dispatch(
        employee_monthly_timesheet({
          body: {
            start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
            end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
            store_code: state.location_codes,
            departments: state.department_codes,
            page: state.page,
            limit: state.limit,
          },
          end_point: "/time-entries/get-time-sheet-dwmy",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const export_xlsx_file = async () => {
    try {
      const {
        timesheet_date_checked,
        start_date,
        end_date,
        employee_code,
        employee_name,
        department,
        department_codes,
        designation,
        location,
        location_codes,
      } = state;

      if (
        !start_date &&
        !end_date &&
        !employee_code &&
        !employee_name &&
        !department &&
        !designation &&
        !location
      ) {
        toast.error(
          set_localize_content(
            employee_timesheet_errors_translation?.search_criteria_not_select,
            "en"
          )
        );
      } else if (!timesheet_date_checked) {
        toast.error(
          set_localize_content(
            employee_timesheet_errors_translation?.timesheet_date_required,
            "en"
          )
        );
      } else {
        let data = {
          start_date: start_date,
          end_date: end_date,
          employee_code: employee_code ? employee_code : null,
          employee_name: employee_name ? employee_name : null,
          departments:
            department !== null
              ? [Number(department?.value)]
              : department_codes,
          designations:
            designation !== null ? [Number(designation?.value)] : null,
          store_code:
            location !== null ? [Number(location?.value)] : location_codes,
        };

        remove_null_keys(data);

        dispatch(
          export_employee_monthly_timesheet({
            body: data,
            end_point: "/time-entries/get-time-sheet-dwmy",
          })
        );
      }

      dispatch(
        create_logger({
          body: {
            resource_section: "timesheet",
            resource_type: "timesheet",
            action_performed: export_label,
          },
          end_point: "/audit-logs/",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handle_detail_dialog = (event: any, row: any, index: any) => {
    let single_attendence = [] as any;

    timesheet_slicer?.monthly_employee_timesheet[index]?.time_data?.map(
      (item: any) => {
        return single_attendence.push([
          {
            name:
              moment(item?.dateeee).format("ddd, DD.MMMM.YYYY") ??
              "Not Available",
            alignment: "center",
            style: { fontSize: "14px" },
          },
          {
            name:
              item?.start_time === null
                ? "--:--"
                : convert_utc_to_user_timezone(item?.start_time),
            alignment: "center",
            style: { fontSize: "14px" },
          },
          {
            name: item?.start_time_difference?.substring(0, 5) ?? "--:--",
            alignment: "center",
            style:
              item?.start_time_difference === null ||
              (item?.start_time_difference?.substring(0, 2) === "00" &&
                Number(item?.start_time_difference?.substring(3, 5)) <= 15)
                ? { fontSize: "14px" }
                : {
                    fontSize: "14px",
                    backgroundColor: "rgb(244, 67, 54)",
                    color: "rgb(255, 255, 255)",
                  },
          },
          {
            name:
              item?.end_time === null
                ? "--:--"
                : convert_utc_to_user_timezone(item?.end_time),
            alignment: "center",
            style: { fontSize: "14px" },
          },
          {
            name: item?.end_time_difference?.substring(0, 5) ?? "--:--",
            alignment: "center",
            style:
              item?.end_time_difference === null ||
              (item?.end_time_difference?.substring(0, 2) === "00" &&
                Number(item?.end_time_difference?.substring(3, 5)) <= 15)
                ? { fontSize: "14px" }
                : {
                    fontSize: "14px",
                    backgroundColor: "rgb(244, 67, 54)",
                    color: "rgb(255, 255, 255)",
                  },
          },
          {
            name:
              item?.duration === null
                ? "--:--"
                : item?.duration?.substring(0, 5),
            alignment: "center",
            style: { fontSize: "14px" },
          },
          {
            name:
              item?.expected_days_off?.toLowerCase() === "present" ||
              item?.expected_days_off === null
                ? item?.expected_hours === null
                  ? "--:--"
                  : item?.expected_hours?.substring(0, 5)
                : item?.expected_days_off,
            hover:
              (item?.expected_days_off?.toLowerCase() === "present" ||
                item?.expected_days_off === null) &&
              item?.start_shift_time !== null &&
              item?.end_shift_time !== null
                ? "Shift Start Time: " +
                  convert_utc_to_user_timezone(item?.start_shift_time) +
                  " | Shift End Time: " +
                  convert_utc_to_user_timezone(item?.end_shift_time)
                : item?.start_shift_time === null &&
                  item?.end_shift_time === null
                ? "No Shift Assigned"
                : item?.expected_days_off,
            alignment: "center",
            style:
              item?.expected_days_off?.toLowerCase() === "leave"
                ? {
                    backgroundColor: "chocolate",
                    color: "#fff",
                    fontSize: "14px",
                  }
                : item?.expected_days_off?.toLowerCase() === "off"
                ? {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    fontSize: "14px",
                  }
                : item?.expected_days_off?.toLowerCase() === "holiday"
                ? {
                    backgroundColor: "blueviolet",
                    color: "#fff",
                    fontSize: "14px",
                  }
                : { fontSize: "14px" },
          },
        ]);
      }
    );

    setState({
      is_dialog_open: true,
      attendence_record: single_attendence,
      emp_index: index,
    });
  };

  useEffect(() => {
    get_breadcrumbs();
    get_all_departments();
    get_all_designations();
    get_all_locations();

    return () => {
      dispatch(handle_clear_form(null as any));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let departments = [],
      department_codes = [];

    if (department_slicer?.all_departments_data?.length > 0) {
      for (
        let index = 0;
        index < department_slicer?.all_departments_data?.length;
        index++
      ) {
        const element = department_slicer?.all_departments_data[index];

        let indez = user?.departments?.findIndex((item: any) => {
          return Number(item?._id) === Number(element?._id);
        });

        if (element?.is_active === true && indez > -1) {
          department_codes.push(Number(element?._id));
          departments.push({
            label: `${element?.department_name}`,
            value: Number(element?._id),
          });
        }
      }

      setState({
        departments_list: departments,
        department_codes,
      });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(department_slicer?.all_departments_data)]);

  useEffect(() => {
    let designation = [];
    let departments_arr = [state.department];

    if (departments_arr?.[0] === null) {
      let designations_data = designation_slicer?.all_designations_data?.filter(
        (itemx: any) => {
          return user?.departments?.some((item: any) => {
            return Number(item?._id) === Number(itemx?.department_id);
          });
        }
      );

      for (let index = 0; index < designations_data.length; index++) {
        const element = designations_data[index];

        designation.push({
          label: `${element?.designation_name}`,
          value: element?._id ?? null,
        });
      }
    } else {
      let designations_data = designation_slicer?.all_designations_data?.filter(
        (itemx: any) => {
          return departments_arr?.some((item: any) => {
            return Number(item?.value) === Number(itemx?.department_id);
          });
        }
      );

      for (let index = 0; index < designations_data.length; index++) {
        const element = designations_data[index];

        designation.push({
          label: `${element?.designation_name}`,
          value: element?._id ?? null,
        });
      }
    }

    setState({
      designations_list: designation,
    });
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(designation_slicer?.all_designations_data),
    state.department,
  ]);

  useEffect(() => {
    let _locations = [] as any;
    let location_codes = [] as any;

    for (
      let index = 0;
      index < employee_slicer?.get_all_employee_locations?.length;
      index++
    ) {
      const item = employee_slicer?.get_all_employee_locations[index];

      let indez = user?.locations?.findIndex((element: any) => {
        return Number(element?.location_code) === Number(item?.location_code);
      });

      if (indez > -1) {
        location_codes.push(Number(item?.location_code));

        _locations.push({
          label: `${item?.location_name}`,
          value: Number(item?.location_code),
        });
      }
    }

    setState({
      location_codes,
      locations_list: _locations,
    });
    // eslint-disable-next-line
  }, [JSON.stringify(employee_slicer?.get_all_employee_locations)]);

  useEffect(() => {
    dispatch(
      employee_monthly_timesheet({
        body: {
          start_date: state?.start_date,
          end_date: state?.end_date,
          store_code: state.location_codes,
          departments: state.department_codes,
          page: state.page,
          limit: state.limit,
        },
        end_point: "/time-entries/get-time-sheet-dwmy",
      })
    );
    // eslint-disable-next-line
  }, [state.location_codes, state.department_codes]);

  useEffect(() => {
    if (
      timesheet_slicer?.export_monthly_employee_timesheet?.emp_timesheet_data
        ?.length > 0
    ) {
      const file_name =
        moment(new Date()).format("DD.MMM.YYYY") +
        "_" +
        set_localize_content(
          employee_timesheet_translation?.employee_timesheet_heading,
          "en"
        ) +
        ".xlsx";

      const ws = utils.json_to_sheet(
        timesheet_slicer?.export_monthly_employee_timesheet?.emp_timesheet_data
      );

      const wb = utils.book_new();

      utils.book_append_sheet(
        wb,
        ws,
        set_localize_content(
          employee_timesheet_translation?.timesheet_report,
          "en"
        )
      );

      writeFile(wb, file_name);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(timesheet_slicer?.export_monthly_employee_timesheet)]);

  return (
    <div className={classes.main_layout_padding}>
      {permissions?.read ? (
        <Accordion
          expanded={state.expand_panel}
          onChange={() => {
            handle_collapse_panel(!state.expand_panel);
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={"blue-background"}
          >
            <Typography
              style={{
                color: "white",
                fontFamily: "BebasNeue-Bold",
                textTransform: "uppercase",
                fontSize: 32,
              }}
            >
              {set_localize_content(reusable_translation?.search, "en")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <div className={"padding-con"}>
              <Grid container spacing={2}>
                <Grid xs={12} md={12} item>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "2px 0px 0px 0px",
                      position: "relative",
                      marginRight: "5px",
                    }}
                    className="search-filters-box"
                  >
                    <label
                      style={{
                        position: "absolute",
                        top: -15,
                        backgroundColor: "white",
                        padding: "0px 10px 0px 9px",
                        fontSize: "14px",
                        color: "black",
                        fontWeight: "bolder",
                        letterSpacing: "1px",
                        fontFamily: "BebasNeue-Bold",
                      }}
                    >
                      {set_localize_content(
                        employee_timesheet_filters_translation?.search_by_employee,
                        "en"
                      )}
                    </label>

                    <FormControlLabel
                      disabled={state.export_file}
                      control={
                        <Checkbox
                          name="timesheet_date_checked"
                          onChange={(event: any) => {
                            handle_monthly_timesheet_search_checkboxes(event);
                          }}
                          checked={state.timesheet_date_checked}
                        />
                      }
                      label={
                        <React.Fragment>
                          <span
                            style={{
                              fontWeight: 600,
                              textTransform: "uppercase",
                            }}
                            className="field_label_txt"
                          >
                            {set_localize_content(
                              employee_timesheet_filters_translation?.timesheet_date,
                              "en"
                            )}
                          </span>
                        </React.Fragment>
                      }
                    />

                    <FormControlLabel
                      disabled={state.export_file}
                      control={
                        <Checkbox
                          name="employee_name_checked"
                          onChange={(event: any) => {
                            handle_monthly_timesheet_search_checkboxes(event);
                          }}
                          checked={state.employee_name_checked}
                        />
                      }
                      label={
                        <React.Fragment>
                          <span
                            style={{
                              fontWeight: 600,
                              textTransform: "uppercase",
                            }}
                            className="field_label_txt"
                          >
                            {set_localize_content(
                              employee_timesheet_filters_translation?.employee_name,
                              "en"
                            )}
                          </span>
                        </React.Fragment>
                      }
                    />

                    <FormControlLabel
                      disabled={state.export_file}
                      control={
                        <Checkbox
                          name="employee_code_checked"
                          onChange={(event: any) => {
                            handle_monthly_timesheet_search_checkboxes(event);
                          }}
                          checked={state.employee_code_checked}
                        />
                      }
                      label={
                        <React.Fragment>
                          <span
                            style={{
                              fontWeight: 600,
                              textTransform: "uppercase",
                            }}
                            className="field_label_txt"
                          >
                            {set_localize_content(
                              employee_timesheet_filters_translation?.employee_code,
                              "en"
                            )}
                          </span>
                        </React.Fragment>
                      }
                    />

                    <FormControlLabel
                      disabled={state.export_file}
                      control={
                        <Checkbox
                          name="department_checked"
                          onChange={(event: any) => {
                            handle_monthly_timesheet_search_checkboxes(event);
                          }}
                          checked={state.department_checked}
                        />
                      }
                      label={
                        <React.Fragment>
                          <span
                            style={{
                              fontWeight: 600,
                              textTransform: "uppercase",
                            }}
                            className="field_label_txt"
                          >
                            {set_localize_content(
                              employee_timesheet_filters_translation?.employee_department,
                              "en"
                            )}
                          </span>
                        </React.Fragment>
                      }
                    />

                    <FormControlLabel
                      disabled={state.export_file}
                      control={
                        <Checkbox
                          name="designation_checked"
                          onChange={(event: any) => {
                            handle_monthly_timesheet_search_checkboxes(event);
                          }}
                          checked={state.designation_checked}
                        />
                      }
                      label={
                        <React.Fragment>
                          <span
                            style={{
                              fontWeight: 600,
                              textTransform: "uppercase",
                            }}
                            className="field_label_txt"
                          >
                            {set_localize_content(
                              employee_timesheet_filters_translation?.employee_designation,
                              "en"
                            )}
                          </span>
                        </React.Fragment>
                      }
                    />

                    <FormControlLabel
                      disabled={state.export_file}
                      control={
                        <Checkbox
                          name="location_checked"
                          onChange={(event: any) => {
                            handle_monthly_timesheet_search_checkboxes(event);
                          }}
                          checked={state.location_checked}
                        />
                      }
                      label={
                        <React.Fragment>
                          <span
                            style={{
                              fontWeight: 600,
                              textTransform: "uppercase",
                            }}
                            className="field_label_txt"
                          >
                            {set_localize_content(
                              employee_timesheet_filters_translation?.employee_location,
                              "en"
                            )}
                          </span>
                        </React.Fragment>
                      }
                    />
                  </div>
                </Grid>

                <Grid xs={12} item container spacing={2}>
                  {state.timesheet_date_checked ? (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <DateRangeFilter
                            input_label={set_localize_content(
                              datatable_translation?.duration,
                              "en"
                            )}
                            disableClearable={true}
                            placeholder={set_localize_content(
                              datatable_translation?.duration,
                              "en"
                            )}
                            inputProps={{
                              className: classNames(
                                classes?.input_feild,
                                "Roboto-Medium"
                              ),
                            }}
                            InputProps={{
                              className: classNames(
                                classes?.input_feild,
                                "Roboto-Medium"
                              ),
                            }}
                            onChange={handle_filter_date}
                            show_output={true}
                            classes={classes}
                            static_width={true}
                            date_range={state?.date_range}
                          />
                        </Grid>

                        <Grid xs={12} md={4} item>
                          <DatePickerNew
                            required={false}
                            input_label={set_localize_content(
                              employee_timesheet_filters_translation?.timesheet_start_date,
                              "en"
                            )}
                            handleDateChange={(newValue: any) =>
                              handle_date_picker(newValue, "start_date")
                            }
                            disabled={state?.export_file}
                            value={state?.start_date ?? null}
                            format={"DD.MMM.YYYY"}
                          />
                        </Grid>

                        <Grid xs={12} md={4} item>
                          <DatePickerNew
                            required={false}
                            input_label={set_localize_content(
                              employee_timesheet_filters_translation?.timesheet_end_date,
                              "en"
                            )}
                            handleDateChange={(newValue: any) =>
                              handle_date_picker(newValue, "end_date")
                            }
                            disabled={state?.export_file}
                            value={state?.end_date ?? null}
                            format={"DD.MMM.YYYY"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}

                  {state.employee_name_checked ? (
                    <Grid xs={12} md={3} item>
                      <div style={{ display: "flex" }}>
                        <span
                          style={{
                            padding: "4px 0px 4px 10px",
                            fontFamily: "BebasNeue-Book",
                            fontSize: "16px",
                            fontWeight: 900,
                            lineHeight: "normal",
                            letterSpacing: "1px",
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {set_localize_content(
                            employee_timesheet_filters_translation?.employee_name,
                            "en"
                          )}
                        </span>
                      </div>

                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={set_localize_content(
                          employee_timesheet_filters_translation?.employee_name_placeholder,
                          "en"
                        )}
                        type={"text"}
                        variant={"outlined"}
                        id="demo-customized-textbox"
                        fullWidth
                        onChange={(e) => {
                          handle_input_change(e);
                        }}
                        classes={classes}
                        name="employee_name"
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                          style: {
                            background: "white",
                            height: 15,
                          },
                        }}
                        InputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        value={state.employee_name}
                      />
                    </Grid>
                  ) : null}

                  {state.employee_code_checked ? (
                    <Grid xs={12} md={3} item>
                      <div style={{ display: "flex" }}>
                        <span
                          style={{
                            padding: "4px 0px 4px 10px",
                            fontFamily: "BebasNeue-Book",
                            fontSize: "16px",
                            fontWeight: 900,
                            lineHeight: "normal",
                            letterSpacing: "1px",
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {set_localize_content(
                            employee_timesheet_filters_translation?.employee_code,
                            "en"
                          )}
                        </span>
                      </div>

                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={set_localize_content(
                          employee_timesheet_filters_translation?.employee_code_placeholder,
                          "en"
                        )}
                        type={"text"}
                        variant={"outlined"}
                        id="demo-customized-textbox"
                        fullWidth
                        onChange={(e) => {
                          handle_input_change(e);
                        }}
                        classes={classes}
                        name="employee_code"
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                          style: {
                            background: "white",
                            height: 15,
                          },
                        }}
                        InputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        value={state.employee_code}
                      />
                    </Grid>
                  ) : null}

                  {state.department_checked ? (
                    <Grid xs={12} md={3} item>
                      <SelectField
                        multiple={false}
                        options={state.departments_list ?? []}
                        value={state.department}
                        variant={"outlined"}
                        placeholder={
                          !state.department
                            ? set_localize_content(
                                employee_timesheet_filters_translation?.employee_department_placeholder,
                                "en"
                              )
                            : ""
                        }
                        InputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        classes={classes}
                        input_label={set_localize_content(
                          employee_timesheet_filters_translation?.employee_department,
                          "en"
                        )}
                        required={false}
                        disableClearable={true}
                        readOnly={state.export_file}
                        handleSelect={(event, name, value) => {
                          setState({ department: value, designation: null });
                        }}
                      />
                    </Grid>
                  ) : null}

                  {state.designation_checked ? (
                    <Grid xs={12} md={3} item>
                      <SelectField
                        multiple={false}
                        options={state.designations_list ?? []}
                        value={state.designation}
                        variant={"outlined"}
                        placeholder={
                          !state.designation
                            ? set_localize_content(
                                employee_timesheet_filters_translation?.employee_designation_placeholder,
                                "en"
                              )
                            : ""
                        }
                        InputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        classes={classes}
                        input_label={set_localize_content(
                          employee_timesheet_filters_translation?.employee_designation,
                          "en"
                        )}
                        required={false}
                        disableClearable={true}
                        readOnly={state.export_file}
                        handleSelect={(event, name, value) => {
                          setState({ designation: value });
                        }}
                      />
                    </Grid>
                  ) : null}

                  {state.location_checked ? (
                    <Grid xs={12} md={3} item>
                      <SelectField
                        multiple={false}
                        options={state.locations_list ?? []}
                        value={state.location}
                        variant={"outlined"}
                        placeholder={
                          !state.location
                            ? set_localize_content(
                                employee_timesheet_filters_translation?.employee_location_placeholder,
                                "en"
                              )
                            : ""
                        }
                        InputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        inputProps={{
                          className: classNames(
                            classes?.input_feild,
                            "Roboto-Medium"
                          ),
                        }}
                        classes={classes}
                        input_label={set_localize_content(
                          employee_timesheet_filters_translation?.employee_location,
                          "en"
                        )}
                        required={false}
                        disableClearable={true}
                        readOnly={state.export_file}
                        handleSelect={(event, name, value) => {
                          setState({ location: value });
                        }}
                      />
                    </Grid>
                  ) : null}

                  <Grid
                    xs={12}
                    md={12}
                    spacing={2}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingBottom: 20,
                      paddingTop: 20,
                    }}
                  >
                    <Button
                      size={"small"}
                      value={set_localize_content(
                        reusable_translation?.clear,
                        "en"
                      )}
                      variant={"outlined"}
                      style={{
                        marginRight: 10,
                        width: 120,
                      }}
                      classesNames={classNames(classes?.red_outlined_button)}
                      startIcon={<Delete />}
                      onClick={() => {
                        clear_all_filters();
                      }}
                    />

                    {permissions?.download ? (
                      <>
                        <Button
                          size={"small"}
                          value={set_localize_content(
                            reusable_translation?.export_excel,
                            "en"
                          )}
                          variant={"outlined"}
                          onClick={() => {
                            export_xlsx_file();
                          }}
                          style={{ marginRight: 10 }}
                          classesNames={classNames(
                            classes.button_width,
                            classes.excel_outlined_button
                          )}
                          startIcon={<FileDownload />}
                        />
                        <PDFDownloadLink
                          document={
                            <EmployeeMonthlyTimesheetPDF
                              data={
                                timesheet_slicer?.monthly_employee_timesheet
                              }
                              start_date={
                                timesheet_slicer?.monthly_emp_timesheet_payload
                                  ?.start_date
                              }
                              end_date={
                                timesheet_slicer?.monthly_emp_timesheet_payload
                                  ?.end_date
                              }
                            />
                          }
                          fileName={"Employee Timesheet"}
                        >
                          <Button
                            size={"small"}
                            value={set_localize_content(
                              reusable_translation?.export_pdf,
                              "en"
                            )}
                            variant={"outlined"}
                            onClick={() => {
                              dispatch(
                                create_logger({
                                  body: {
                                    resource_section: "employee timesheet",
                                    resource_type: "employee timesheet",
                                    action_performed: print_label,
                                  },
                                  end_point: "/audit-logs/",
                                })
                              );
                            }}
                            style={{ marginRight: 10 }}
                            classesNames={classNames(
                              classes.button_width,
                              classes.pdf_outlined_button
                            )}
                            startIcon={<PictureAsPdf />}
                          />
                        </PDFDownloadLink>
                      </>
                    ) : (
                      ""
                    )}

                    <Button
                      size={"small"}
                      value={set_localize_content(
                        reusable_translation?.search,
                        "en"
                      )}
                      variant={"outlined"}
                      onClick={() => {
                        get_timesheet_search_filters();
                      }}
                      style={{ width: 120 }}
                      classesNames={classNames(
                        classes.button_width,
                        classes.outlined_button
                      )}
                      startIcon={<Search />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}

      {!state.expand_panel ? <br /> : null}

      <ItemTable
        {...props}
        hide_sn={false}
        columns={[
          {
            name: set_localize_content(
              employee_timesheet_translation?.employee_name,
              "en"
            ),
          },
          {
            name: set_localize_content(
              employee_timesheet_translation?.location,
              "en"
            ),
          },
          {
            name: set_localize_content(
              employee_timesheet_translation?.designation,
              "en"
            ),
          },
          {
            name: set_localize_content(
              employee_timesheet_translation?.acutal_hours,
              "en"
            ),
            alignment: "center",
          },
          {
            name: set_localize_content(
              employee_timesheet_translation?.expected_hours,
              "en"
            ),
            alignment: "center",
          },
        ]}
        rows={
          permissions?.read
            ? timesheet_slicer?.monthly_employee_timesheet_data
            : []
        }
        table_width={"100%"}
        min_width={"max-content"}
        show_header={false}
        date_range={false}
        filterByType={false}
        searchEnable={false}
        show_row_count={false}
        sticky_header={true}
        onRowClick={(event, row, index) => {
          handle_detail_dialog(event, row, index);
        }}
        enablePagination={permissions?.read ? true : false}
        previous_count={Number(
          timesheet_slicer?.monthly_emp_timesheet_payload?.previous_count ?? 0
        )}
        current_count={Number(
          timesheet_slicer?.monthly_emp_timesheet_payload?.current_count ?? 0
        )}
        total_records={Number(
          timesheet_slicer?.monthly_emp_timesheet_payload?.total_count ?? 0
        )}
        count={Number(
          timesheet_slicer?.monthly_emp_timesheet_payload?.total_pages ?? 1
        )}
        handleUpdateRecords={get_timesheet_by_filter}
      />

      <DraggableDialog
        dialog_title={set_localize_content(
          employee_timesheet_translation?.timesheet_report,
          "en"
        )}
        maxWidth={"lg"}
        classes={classes}
        open={state?.is_dialog_open}
        handleClose={() => {
          setState({
            is_dialog_open: false,
          });
        }}
        draggable={false}
        dialogBody={
          <Grid container spacing={2} style={{ paddingTop: 16 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ textAlign: "left" }}
            >
              <Typography>
                <b
                  style={{
                    fontFamily: "BebasNeue-Bold",
                    fontSize: "22px",
                    textTransform: "uppercase",
                    padding: "0px 20px",
                  }}
                >
                  {
                    timesheet_slicer?.monthly_employee_timesheet?.[
                      state?.emp_index
                    ]?.emp_full_name
                  }{" "}
                  -{" "}
                  {
                    timesheet_slicer?.monthly_employee_timesheet?.[
                      state?.emp_index
                    ]?.designation_name
                  }
                </b>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ textAlign: "right" }}
            >
              <Typography>
                <b
                  style={{
                    fontFamily: "BebasNeue-Bold",
                    fontSize: "22px",
                    textTransform: "uppercase",
                    padding: "0px 20px",
                  }}
                >
                  {moment(state?.start_date).format("DD.MMM.YYYY")} -{" "}
                  {moment(state?.end_date).format("DD.MMM.YYYY")}
                </b>
              </Typography>
            </Grid>

            <Grid xs={12} sm={12} md={12} lg={12} item>
              <ItemTable
                {...props}
                hide_sn={false}
                columns={[
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.day_and_date,
                      "en"
                    ),
                    alignment: "center",
                    style: { fontWeight: 900, fontSize: "18px" },
                  },
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.clock_in,
                      "en"
                    ),
                    alignment: "center",
                    style: {
                      fontWeight: 900,
                      fontSize: "18px",
                      backgroundColor: "#64BF31",
                    },
                  },
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.clock_in_difference,
                      "en"
                    ),
                    alignment: "center",
                    style: {
                      fontWeight: 900,
                      fontSize: "18px",
                      backgroundColor: "#64BF31",
                    },
                  },
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.clock_out,
                      "en"
                    ),
                    alignment: "center",
                    style: {
                      fontWeight: 900,
                      fontSize: "18px",
                      backgroundColor: "#E14E4E",
                    },
                  },
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.clock_out_difference,
                      "en"
                    ),
                    alignment: "center",
                    style: {
                      fontWeight: 900,
                      fontSize: "18px",
                      backgroundColor: "#E14E4E",
                    },
                  },
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.acutal_hours,
                      "en"
                    ),
                    alignment: "center",
                    style: { fontWeight: 900, fontSize: "18px" },
                  },
                  {
                    name: set_localize_content(
                      employee_timesheet_translation?.expected_hours,
                      "en"
                    ),
                    alignment: "center",
                    style: { fontWeight: 900, fontSize: "18px" },
                  },
                ]}
                rows={permissions?.read ? state?.attendence_record : []}
                table_width={"100%"}
                min_width={"max-content"}
                show_header={false}
                date_range={false}
                filterByType={false}
                searchEnable={false}
                show_row_count={false}
                sticky_header={true}
                custom_table_head={true}
              />
            </Grid>
          </Grid>
        }
      />

      {timesheet_slicer.activity ? (
        <LoadingComponent activity={timesheet_slicer.activity} />
      ) : null}
    </div>
  );
}
