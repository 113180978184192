import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
export default function LoadingComponent(props: any) {
  const { activity } = props;
  return (
    <Dialog
      open={activity}
      maxWidth="sm"
      hideBackdrop
      PaperProps={{
        style: {
          background: "transparent",
          boxShadow: "none",
          overflow: "hidden",
        },
      }}
    >
      <DialogContent
        style={{
          overflow: "hidden",
        }}
      >
        <section>
          <div id="preloader">
            <div id="ctn-preloader" className="ctn-preloader">
              <div className="animation-preloader">
                <img
                  src={require("../../assets/images/loader/loader.gif")}
                  alt=""
                  width={100}
                />
              </div>
            </div>
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );
}

LoadingComponent.defaultProps = {
  activity: false,
};

LoadingComponent.propTypes = {
  activity: PropTypes.bool,
};
