import { KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete, Paper, TextField } from "@mui/material";
import React from "react";
import { date_range_filters, default_label_colors } from "utils/constants";
import PropTypes from "prop-types";
import moment from "moment";
import { search_filters_translation } from "translation";
import { set_localize_content } from "utils/helper";

export default function DateRangeFilter(props: any) {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      filter: { label: "This Week", value: "this week" },
      start_date: null,
      end_date: null,
    }
  );

  const {
    input_label,
    error,
    isDisabled,
    name,
    variant,
    placeholder,
    readOnly,
    disableClearable,
    remove_padding,
    show_output,
    static_width,
    date_range,
  } = props;

  const handleChange = (e: any, value: any) => {
    try {
      setState({
        filter: value,
      });
      const today = new Date();

      switch (value?.value) {
        case "today":
          setState({
            start_date: moment(new Date()).format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment(new Date()).format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.today,
                  "en"
                ),
                value: "today",
              },
            });
          }
          break;
        case "this_week":
          setState({
            start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
            end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
              end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_week,
                  "en"
                ),
                value: "this_week",
              },
            });
          }
          break;
        case "this_week_to_date":
          setState({
            start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_week_to_date,
                  "en"
                ),
                value: "this_week_to_date",
              },
            });
          }
          break;
        case "this_month":
          setState({
            start_date: moment().startOf("month").format("YYYY-MM-DD"),
            end_date: moment().endOf("month").format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("month").format("YYYY-MM-DD"),
              end_date: moment().endOf("month").format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_month,
                  "en"
                ),
                value: "this_month",
              },
            });
          }
          break;
        case "this_month_to_date":
          setState({
            start_date: moment().startOf("month").format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("month").format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_month_to_date,
                  "en"
                ),
                value: "this_month_to_date",
              },
            });
          }
          break;
        case "this_quarter":
          setState({
            start_date: moment().startOf("quarter").format("YYYY-MM-DD"),
            end_date: moment().endOf("quarter").format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("quarter").format("YYYY-MM-DD"),
              end_date: moment().endOf("quarter").format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_quarter,
                  "en"
                ),
                value: "this_quarter",
              },
            });
          }
          break;
        case "this_quarter_to_date":
          setState({
            start_date: moment().startOf("quarter").format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("quarter").format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_quarter_to_date,
                  "en"
                ),
                value: "this_quarter_to_date",
              },
            });
          }
          break;
        case "this_year":
          setState({
            start_date: moment().startOf("year").format("YYYY-MM-DD"),
            end_date: moment().endOf("year").format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("year").format("YYYY-MM-DD"),
              end_date: moment().endOf("year").format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_year,
                  "en"
                ),
                value: "this_year",
              },
            });
          }
          break;
        case "this_year_to_date":
          setState({
            start_date: moment().startOf("year").format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().startOf("year").format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.this_year_to_date,
                  "en"
                ),
                value: "this_year_to_date",
              },
            });
          }
          break;
        case "last_seven_days":
          setState({
            start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
              end_date: moment().format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_7_days,
                  "en"
                ),
                value: "last_seven_days",
              },
            });
          }
          break;
        case "yesterday":
          setState({
            start_date: moment(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
              )
            ).format("YYYY-MM-DD"),

            end_date: moment(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate() - 1
              )
            ).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment(
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - 1
                )
              ).format("YYYY-MM-DD"),

              end_date: moment(
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate() - 1
                )
              ).format("YYYY-MM-DD"),

              date_range: {
                label: set_localize_content(
                  search_filters_translation?.yesterday,
                  "en"
                ),
                value: "yesterday",
              },
            });
          }
          break;
        case "last_week":
          setState({
            start_date: moment()
              .subtract(1, "weeks")
              .startOf("isoWeek")
              .format("YYYY-MM-DD"),
            end_date: moment()
              .subtract(1, "weeks")
              .endOf("isoWeek")
              .format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "weeks")
                .startOf("isoWeek")
                .format("YYYY-MM-DD"),
              end_date: moment()
                .subtract(1, "weeks")
                .endOf("isoWeek")
                .format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_week,
                  "en"
                ),
                value: "last_week",
              },
            });
          }
          break;
        case "last_week_to_date":
          setState({
            start_date: moment()
              .subtract(1, "weeks")
              .startOf("isoWeek")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "weeks")
                .startOf("isoWeek")
                .format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_week_to_date,
                  "en"
                ),
                value: "last_week_to_date",
              },
            });
          }
          break;
        case "last_month":
          setState({
            start_date: moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD"),
              end_date: moment()
                .subtract(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_month,
                  "en"
                ),
                value: "last_month",
              },
            });
          }
          break;
        case "last_month_to_date":
          setState({
            start_date: moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_month_to_date,
                  "en"
                ),
                value: "last_month_to_date",
              },
            });
          }
          break;
        case "last_quarter":
          setState({
            start_date: moment()
              .subtract(1, "quarters")
              .startOf("quarter")
              .format("YYYY-MM-DD"),
            end_date: moment()
              .subtract(1, "quarters")
              .endOf("quarter")
              .format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "quarters")
                .startOf("quarter")
                .format("YYYY-MM-DD"),
              end_date: moment()
                .subtract(1, "quarters")
                .endOf("quarter")
                .format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_quarter,
                  "en"
                ),
                value: "last_quarter",
              },
            });
          }
          break;
        case "last_quarter_to_date":
          setState({
            start_date: moment()
              .subtract(1, "quarters")
              .startOf("quarter")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "quarters")
                .startOf("quarter")
                .format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_quarter_to_date,
                  "en"
                ),
                value: "last_quarter_to_date",
              },
            });
          }
          break;
        case "last_year":
          setState({
            start_date: moment()
              .subtract(1, "years")
              .startOf("year")
              .format("YYYY-MM-DD"),
            end_date: moment()
              .subtract(1, "years")
              .endOf("year")
              .format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "years")
                .startOf("year")
                .format("YYYY-MM-DD"),
              end_date: moment()
                .subtract(1, "years")
                .endOf("year")
                .format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_year,
                  "en"
                ),
                value: "last_year",
              },
            });
          }
          break;
        case "last_year_to_date":
          setState({
            start_date: moment()
              .subtract(1, "years")
              .startOf("year")
              .format("YYYY-MM-DD"),
            end_date: moment(new Date()).format("YYYY-MM-DD"),
          });

          if (props.onChange) {
            props?.onChange({
              start_date: moment()
                .subtract(1, "years")
                .startOf("year")
                .format("YYYY-MM-DD"),
              end_date: moment(new Date()).format("YYYY-MM-DD"),
              date_range: {
                label: set_localize_content(
                  search_filters_translation?.last_year_to_date,
                  "en"
                ),
                value: "last_year_to_date",
              },
            });
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        position: "relative",
        width: "100%",
        marginBottom: static_width ? -24 : 0,
      }}
    >
      {input_label && (
        <label
          style={{
            padding: "4px 0px 4px 10px",
            fontFamily: "BebasNeue-Book",
            fontSize: "16px",
            fontWeight: 900,
            lineHeight: "normal",
            letterSpacing: "1px",
            color: "black",
            textTransform: "uppercase",
          }}
        >
          {input_label}
        </label>
      )}

      <Autocomplete
        value={date_range}
        options={date_range_filters}
        getOptionLabel={(option) => option.label}
        fullWidth
        disabled={isDisabled}
        placeholder={placeholder}
        style={{
          background: isDisabled ? "#f6f6f6" : "transparent",
          color: default_label_colors.color_e,
        }}
        size="small"
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              "& ul": {
                "& li": {
                  fontFamily: "Roboto-Regular",
                  fontSize: 16,
                  fontWeight: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: default_label_colors.color_e,
                  borderBottom: "1px solid #E0E0E0",
                },
              },
            }}
            style={{
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
              maxHeight: 200,
              overflow: "hidden scroll",
            }}
          >
            {children}
          </Paper>
        )}
        popupIcon={
          <KeyboardArrowDown style={{ color: default_label_colors.color_f }} />
        }
        autoComplete={false}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            size="small"
            autoComplete="off"
            aria-autocomplete="none"
            placeholder={placeholder}
            error={error}
            variant={variant}
            inputProps={{
              ...params.inputProps,
              style: {
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                fontWeight: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                backgroundColor: isDisabled ? "#f6f6f6" : "transparent",
                background: isDisabled ? "#f6f6f6" : "transparent",
                color: default_label_colors.color_e,
                height: 27,
              },
              autocomplete: "off",
              "aria-autocomplete": "none",
            }}
            InputProps={{
              ...params.InputProps,
              style: { background: isDisabled ? "#f6f6f6" : "white" },
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              autoComplete: "off",
            }}
            style={{ background: isDisabled ? "#f6f6f6" : "white" }}
          />
        )}
        disableClearable={disableClearable}
        readOnly={readOnly}
      />

      <span
        style={
          remove_padding
            ? {
                fontSize: 14,
                color: "gray",
                position: "absolute",
                bottom: -22,
                fontFamily: "Roboto-Italic",
                left: 0,
                right: 0,
              }
            : {
                fontSize: 14,
                color: "gray",
                fontFamily: "Roboto-Regular",
              }
        }
      >
        {state.start_date && state.end_date && !show_output ? (
          <>
            from {moment(state.start_date).format("DD-MMM-YYYY")} to{" "}
            {moment(state.end_date).format("DD-MMM-YYYY")}
          </>
        ) : (
          "ㅤ"
        )}
      </span>
    </form>
  );
}

DateRangeFilter.defaultProps = {
  input_label: "",
  required: false,
  error: false,
  isDisabled: false,
  classes: {},
  size: "small",
  variant: "outlined",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  disableClearable: false,
  readOnly: false,
  remove_padding: false,
  onChange: () => {},
  show_output: false,
  static_width: false,
  date_range: { label: "This Week", value: "this_week" },
};

DateRangeFilter.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  remove_padding: PropTypes.bool,
  onChange: PropTypes.func,
  show_output: PropTypes.bool,
  static_width: PropTypes.bool,
  date_range: PropTypes.any,
};
