import classNames from "classnames";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

export default function InputField(props: any) {
  const {
    input_label,
    required,
    value,
    classes,
    error,
    rows,
    multiline,
    disabled,
    input_label_2,
    white_input_label,
    onKeyPress,
    helperText,
    type,
    placeholder,
    maxLength,
    autoComplete,
  } = props;

  return (
    <form
      autoSave="off"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        width: "100%",
      }}
    >
      {white_input_label ? (
        <div style={{ display: "flex" }}>
          <label
            className={classNames(
              classes.white_input_label_style,
              "RobotoCondensed-Regular",
              error && classes.color_red
            )}
            style={{ flex: 1 }}
          >
            {input_label}
            {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
        </div>
      ) : input_label_2 ? (
        <div style={{ display: "flex" }}>
          <label
            className={classNames(
              classes.input_label_style,
              "RobotoCondensed-Regular",
              error && classes.color_red
            )}
            style={{ flex: 1 }}
          >
            {input_label}
            {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
          <span style={{ paddingRight: 15 }}>{input_label_2}</span>
        </div>
      ) : input_label ? (
        <label
          className={classNames(
            classes.input_label_style,
            "RobotoCondensed-Regular",
            error && classes.color_red
          )}
        >
          {input_label}
          {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      ) : (
        ""
      )}

      <TextField
        {...props}
        type={type ? type : "text"}
        rows={rows}
        multiline={multiline}
        disabled={disabled}
        onKeyPress={onKeyPress}
        helperText={helperText}
        placeholder={disabled ? "" : placeholder}
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#181818",
          },
        }}
        inputProps={{
          maxLength: maxLength,
          style: {
            color: disabled ? "#181818" : "#181818",
            background: disabled ? "transparent" : "white",
          },
        }}
        autoComplete={autoComplete}
      />
    </form>
  );
}

InputField.defaultProps = {
  input_label: "",
  value: "",
  required: false,
  fullWidth: true,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  type: "text",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  onChange: null,
  rows: 1,
  multiline: false,
  disabled: false,
  autoComplete: "off",
  input_label_2: "",
  white_input_label: false,
  onKeyPress: null,
  helperText: "",
  maxLength: 10000,
};

InputField.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.any,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.any,
  input_label_2: PropTypes.any,
  white_input_label: PropTypes.bool,
  onKeyPress: PropTypes.func,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
};
