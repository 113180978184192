import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { number_format_without_currency } from "utils/helper";

export interface SalesState {
  all_sales: any;
  all_sales_data: any;
  export_sales: any;

  single_sale: any;

  consolidate_sales: any;
  consolidate_sales_data: any;
  export_consolidate_sales: any;
  consolidate_period_data: any;

  channel_sales: any;
  channel_sales_data: any;
  channel_sales_summary: any;

  export_channel_sales: any;
  export_channel_sales_data: any;
  export_channel_sales_summary: any;

  total_orders_total_sales_chart_data: any;
  callcenter_orders_olo_orders_chart_data: any;
  callcenter_sales_olo_sales_chart_data: any;
  callcenter_atp_olo_atp_chart_data: any;

  cost_of_goods_sold_data: any;
  cost_of_goods_sold: any;
  export_cost_of_goods_sold: any;

  consolidate_inventory_data: any;
  consolidate_inventory: any;

  payment_summary: any;
  payment_summary_data: any;
  export_payment_summary: any;
  store_wise_payment_summary_chart: any;

  get_single_sale_with_item: any;
  export_all_items: any;

  consolidate_pulse_inventory: any;
  consolidate_pulse_inventory_data: any;
  export_consolidate_pulse_inventory: any;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: SalesState = {
  all_sales: [],
  all_sales_data: [],
  export_sales: [],

  single_sale: {},

  consolidate_sales: [],
  consolidate_sales_data: [],
  export_consolidate_sales: [],
  consolidate_period_data: [],

  channel_sales: [],
  channel_sales_data: [],
  channel_sales_summary: [],

  export_channel_sales: [],
  export_channel_sales_data: [],
  export_channel_sales_summary: [],

  total_orders_total_sales_chart_data: [],
  callcenter_orders_olo_orders_chart_data: [],
  callcenter_sales_olo_sales_chart_data: [],
  callcenter_atp_olo_atp_chart_data: [],

  cost_of_goods_sold: [],
  cost_of_goods_sold_data: [],
  export_cost_of_goods_sold: [],

  consolidate_inventory_data: [],
  consolidate_inventory: [],

  payment_summary: [],
  payment_summary_data: [],
  export_payment_summary: [],
  store_wise_payment_summary_chart: [],

  get_single_sale_with_item: [],
  export_all_items: [],

  consolidate_pulse_inventory: [],
  consolidate_pulse_inventory_data: [],
  export_consolidate_pulse_inventory: [],

  activity: true,
};

const months = [
  "January",
  "Febraury",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const export_items_request = createAsyncThunk(
  "inventory-usage/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const all_sales = createAsyncThunk("sales", async (data: APIParams) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      data.body,
      "sales"
    );

    return response;
  } catch (err) {
    console.log(err);
  }
});

export const export_sales = createAsyncThunk(
  "sales/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const single_sales = createAsyncThunk(
  "sales/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "sales");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const consolidate_sales_report = createAsyncThunk(
  "sales/get-consolidate",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const get_sale_with_item = createAsyncThunk(
  "inventory-usage",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const consolidate_period_sales_report = createAsyncThunk(
  "sales/get-consolidate-period",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_consolidate_period_sales_report = createAsyncThunk(
  "sales/export-consolidate-period",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const get_latest_sales_records = createAsyncThunk(
  "sales/get-latest",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_latest_sales_store_records = createAsyncThunk(
  "sales/get-latest-store",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const channel_sales_report = createAsyncThunk(
  "sales/get-channel",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return { ...response, is_empty_data: data.is_empty_data };
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_channel_sales_report = createAsyncThunk(
  "sales/export-channel-sales-report",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return { ...response, is_empty_data: data.is_empty_data };
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const get_sales_dashboard = createAsyncThunk(
  "sales/get_sales_dashboard",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const get_cost_of_goods_sold = createAsyncThunk(
  "sales/get-cost-of-goods-sold",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_cost_of_goods_sold = createAsyncThunk(
  "sales/cost-of-goods-sold-export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const consolidate_inventory_report = createAsyncThunk(
  "sales/consolidate-inventory",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_consolidate_inventory_report = createAsyncThunk(
  "sales/consolidate-inventory-export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const payment_summary_report = createAsyncThunk(
  "sales/payment-summary",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_payment_summary_report = createAsyncThunk(
  "sales/export-payment-summary",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const consolidate_pulse_inventory_report = createAsyncThunk(
  "sales/consolidate_pulse_inventory-report",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_consolidate_pulse_inventory_report = createAsyncThunk(
  "sales/export_consolidate_pulse_inventory-report",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "sales"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

export const sales_slicer = createSlice({
  name: "sales",
  initialState,
  reducers: {
    handle_clear_form: (state: SalesState, action: any) => {
      state.single_sale = {};
      state.get_single_sale_with_item = [];
      state.export_sales = [];
      state.export_consolidate_sales = [];
      state.export_channel_sales = [];
      state.export_channel_sales_data = [];
      state.export_channel_sales_summary = [];
      state.export_cost_of_goods_sold = [];
      state.export_payment_summary = [];
      state.export_all_items = [];
      state.export_consolidate_pulse_inventory = [];
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_sales.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_sales.fulfilled, (state, { payload }) => {
        try {
          let sales = [] as any;
          let totalSales = 0 as any;
          let totalOrders = 0 as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            totalSales = Number(totalSales) + Number(item?.total_sales ?? 0);
            totalOrders = Number(totalOrders) + Number(item?.total_orders ?? 0);

            sales.push([
              "SA" + item?.format_column || "Not Available",
              moment(new Date(item?.system_date)).format("DD.MMM.YYYY") ||
                "Not Available",
              item?.name || "Not Available",
              {
                name: Number(!isNaN(item?.total_sales))
                  ? number_format_without_currency(
                      Number(item?.total_sales ?? 0)
                    ) + " ₼"
                  : "0.00 ₼",
                alignment: "center",
              },
              {
                name: Number(item?.total_orders ?? 0).toLocaleString() ?? "0",
                alignment: "center",
              },
              {
                name: Number(!isNaN(item?.total_sales / item?.total_orders))
                  ? number_format_without_currency(
                      Number(item?.total_sales) / Number(item?.total_orders)
                    ) + " ₼"
                  : "0.00 ₼",
                alignment: "center",
              },
            ]);
          }

          sales.push([
            { name: "SUMMARY", alignment: "center", colSpan: 3 },
            {
              name:
                number_format_without_currency(Number(totalSales ?? 0)) + " ₼",
              alignment: "center",
            },
            {
              name: Number(Number(totalOrders ?? 0)).toLocaleString(),
              alignment: "center",
            },
            {
              name: Number(!isNaN(totalSales / totalOrders))
                ? number_format_without_currency(
                    Number(totalSales ?? 0) / Number(totalOrders)
                  ) + " ₼"
                : "0.00 ₼",
              alignment: "center",
            },
          ]);

          state.all_sales_data = payload;
          state.all_sales = sales;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_items_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_items_request.fulfilled, (state, { payload }) => {
        let export_all_items = [] as any;

        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];

          export_all_items.push([
            item?.item_name || "Not Available",

            item?.pulse_item_group_name || "Not Available",

            item?.default_unit_name || "Not Available",

            Number(item?.price) === 0
              ? "0.00"
              : number_format_without_currency(Number(item?.price ?? 0)),

            number_format_without_currency(
              Number(item?.beginning_portion_qty ?? 0)
            ),

            number_format_without_currency(
              Number(item?.delivered_portion_qty ?? 0)
            ),

            number_format_without_currency(
              Number(item?.total_available_balance ?? 0)
            ),

            number_format_without_currency(
              Number(item?.ending_portion_qty ?? 0)
            ),

            number_format_without_currency(Number(item?.actual_usage ?? 0)),

            number_format_without_currency(
              Number(item?.ideal_portion_qty ?? 0)
            ),

            number_format_without_currency(
              Number(item?.actual_ideal_varains ?? 0)
            ),

            Number(item?.actual_usage_amount) === 0
              ? "0.00"
              : number_format_without_currency(
                  Number(item?.actual_usage_amount ?? 0)
                ),

            Number(item?.actual_ideal_varains_amount) === 0
              ? "0.00"
              : number_format_without_currency(
                  Number(item?.actual_ideal_varains_amount ?? 0)
                ),

            Number(item?.actual_vs_ideal_per) === 0
              ? "0.00 %"
              : Number(!isNaN(item?.actual_vs_ideal_per))
              ? number_format_without_currency(
                  Number(item?.actual_vs_ideal_per ?? 0.0)
                ) + " %"
              : "0.00 %",
          ]);
        }

        state.export_all_items = export_all_items;
        state.activity = false;
      })

      .addCase(export_sales.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_sales.fulfilled, (state, { payload }) => {
        try {
          let sales = [] as any;
          let totalSales = 0 as any;
          let totalOrders = 0 as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            totalSales = Number(totalSales) + Number(item?.total_sales ?? 0);
            totalOrders = Number(totalOrders) + Number(item?.total_orders ?? 0);

            sales.push({
              ID: "SA" + item?.format_column || "Not Available",
              "Sales Date":
                moment(new Date(item?.system_date)).format("DD.MMM.YYYY") ||
                "Not Available",
              "Store Name": item?.name || "Not Available",
              "Total Sales": Number(!isNaN(item?.total_sales))
                ? number_format_without_currency(Number(item?.total_sales ?? 0))
                : "0.00",
              "Total Orders":
                Number(item?.total_orders ?? 0).toLocaleString() ?? 0,
              "Avg. Ticket Price": Number(
                !isNaN(item?.total_sales / item?.total_orders)
              )
                ? number_format_without_currency(
                    Number(item?.total_sales) / Number(item?.total_orders)
                  )
                : "0.00",
            });
          }

          sales.reverse();

          sales.push({
            ID: "Not Available",
            "Sales Date": "Not Available",
            "Store Name": "SUMMARY",
            "Total Sales": number_format_without_currency(
              Number(totalSales ?? 0)
            ),
            "Total Orders": Number(totalOrders ?? 0).toLocaleString(),
            "Avg. Ticket Price": Number(!isNaN(totalSales / totalOrders))
              ? number_format_without_currency(
                  Number(totalSales ?? 0) / Number(totalOrders)
                )
              : "0.00",
          });

          state.export_sales = sales;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_sales.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_sales.fulfilled, (state, { payload }) => {
        try {
          state.single_sale = payload?.data[0];
          state.activity = false;
        } catch (error) {}
      })

      .addCase(consolidate_sales_report.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(consolidate_sales_report.fulfilled, (state, { payload }) => {
        try {
          let consolidate_report = [] as any;
          let totalSales = 0 as any;
          let totalOrders = 0 as any;

          payload?.data?.result.sort(function (a: any, b: any) {
            var keyA = new Date(a.store_code),
              keyB = new Date(b.store_code);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          if (payload?.data?.result?.length > 0) {
            for (
              let index = 0;
              index < payload?.data?.result?.length;
              index++
            ) {
              const item = payload?.data?.result[index];
              totalSales = Number(totalSales) + Number(item?.total_sales ?? 0);
              totalOrders =
                Number(totalOrders) + Number(item?.total_orders ?? 0);

              consolidate_report.push([
                {
                  name: item?.store_name ?? "Not Available",
                },
                {
                  name: Number(!isNaN(item?.total_sales))
                    ? number_format_without_currency(
                        Number(item?.total_sales ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name:
                    Number(item?.total_orders) === 0
                      ? "0"
                      : Number(
                          Number(item?.total_orders ?? 0)
                        ).toLocaleString(),
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.total_sales / item?.total_orders))
                    ? number_format_without_currency(
                        Number(item?.total_sales) / Number(item?.total_orders)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
              ]);
            }

            consolidate_report.push([
              "SUMMARY",
              {
                name:
                  number_format_without_currency(Number(totalSales ?? 0)) +
                  " ₼",
                alignment: "center",
              },
              {
                name: Number(Number(totalOrders ?? 0)).toLocaleString(),
                alignment: "center",
              },
              {
                name: Number(!isNaN(totalSales / totalOrders))
                  ? number_format_without_currency(
                      Number(totalSales ?? 0) / Number(totalOrders)
                    ) + " ₼"
                  : "0.00 ₼",
                alignment: "center",
              },
            ]);
          }

          state.consolidate_sales_data = consolidate_report;
          state.consolidate_sales = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(
        consolidate_period_sales_report.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        consolidate_period_sales_report.fulfilled,
        (state, { payload }) => {
          try {
            state.consolidate_period_data = payload;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      .addCase(get_sale_with_item.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_sale_with_item.fulfilled, (state, { payload }) => {
        try {
          let get_single_sale_with_item = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            get_single_sale_with_item.push([
              {
                name: item?.item_name || "Not Available",
                style: {
                  position: "sticky",
                  left: 0,
                  backgroundColor: null,
                },
              },
              {
                name: item?.pulse_item_group_name || "Not Available",
                alignment: "left",
              },
              {
                name: item?.default_unit_name || "Not Available",
                alignment: "center",
              },
              {
                name:
                  Number(item?.price) === 0
                    ? "0.00 ₼"
                    : number_format_without_currency(Number(item?.price ?? 0)) +
                      " ₼",
                alignment: "center",
              },

              {
                name: number_format_without_currency(
                  Number(item?.beginning_portion_qty ?? 0)
                ),
                alignment: "center",
              },

              {
                name: number_format_without_currency(
                  Number(item?.delivered_portion_qty ?? 0)
                ),
                alignment: "center",
              },

              {
                name: number_format_without_currency(
                  Number(item?.total_available_balance ?? 0)
                ),
                alignment: "center",
              },

              {
                name: number_format_without_currency(
                  Number(item?.ending_portion_qty ?? 0)
                ),
                alignment: "center",
              },

              {
                name: number_format_without_currency(
                  Number(item?.actual_usage ?? 0)
                ),
                alignment: "center",
              },
              {
                name: number_format_without_currency(
                  Number(item?.ideal_portion_qty ?? 0)
                ),
                alignment: "center",
              },

              {
                name: number_format_without_currency(
                  Number(item?.actual_ideal_varains ?? 0)
                ),
                alignment: "center",
              },
              {
                name:
                  Number(item?.actual_usage_amount) === 0
                    ? "0.00 ₼"
                    : number_format_without_currency(
                        Number(item?.actual_usage_amount ?? 0)
                      ) + " ₼",
                alignment: "center",
              },

              {
                name:
                  Number(item?.actual_ideal_varains_amount) === 0
                    ? "0.00 ₼"
                    : number_format_without_currency(
                        Number(item?.actual_ideal_varains_amount ?? 0)
                      ) + " ₼",
                alignment: "center",
              },

              {
                name:
                  Number(item?.actual_vs_ideal_per) === 0
                    ? "0.00 %"
                    : Number(!isNaN(item?.actual_vs_ideal_per))
                    ? number_format_without_currency(
                        Number(item?.actual_vs_ideal_per ?? 0.0)
                      ) + " %"
                    : "0.00 %",
                alignment: "center",
              },
            ]);
          }

          state.get_single_sale_with_item = get_single_sale_with_item;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(
        export_consolidate_period_sales_report.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        export_consolidate_period_sales_report.fulfilled,
        (state, { payload }) => {
          try {
            let channel_report = [] as any;
            let channel_summary = [] as any;

            let totalSales = 0 as any;
            let totalOrders = 0 as any;
            let totalATP = 0 as any;

            let period_record = "day" as any;

            if (payload?.data?.length > 0) {
              for (let index = 0; index < payload?.data?.length; index++) {
                const item = payload?.data[index];

                totalSales =
                  Number(totalSales) + Number(item?.total_sales ?? 0);
                totalOrders =
                  Number(totalOrders) + Number(item?.total_orders ?? 0);
                totalATP = Number(totalATP) + Number(item?.total_atp ?? 0);

                period_record = item?.week_day
                  ? "day"
                  : item?.week_number
                  ? "weeek"
                  : item?.month_number
                  ? "month"
                  : item?.year_number
                  ? "year"
                  : "day";

                channel_report.push(
                  item?.week_day
                    ? {
                        "Sales Date":
                          moment(new Date(item?.sales_date)).format(
                            "DD.MMM.YYYY"
                          ) ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        "Total Orders":
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        "Total Sales": Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            )
                          : "0.00",
                        "Avg. Ticket Price":
                          Number(item?.total_atp) === 0
                            ? "0.00"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ),
                      }
                    : item?.week_number
                    ? {
                        "Week Number":
                          "W " + item?.week_number ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        "Total Orders":
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        "Total Sales": Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            )
                          : "0.00",
                        "Avg. Ticket Price":
                          Number(item?.total_atp) === 0
                            ? "0.00"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ),
                      }
                    : item?.month_number
                    ? {
                        Month:
                          months[item?.month_number - 1] +
                            " " +
                            new Date().getFullYear() ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        "Total Orders":
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        "Total Sales": Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            )
                          : "0.00",
                        "Avg. Ticket Price":
                          Number(item?.total_atp) === 0
                            ? "0.00"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ),
                      }
                    : item?.year_number
                    ? {
                        Year: item?.year_number ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        "Total Orders":
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        "Total Sales": Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            )
                          : "0.00",
                        "Avg. Ticket Price":
                          Number(item?.total_atp) === 0
                            ? "0.00"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ),
                      }
                    : {
                        "Sales Date":
                          moment(new Date(item?.sales_date)).format(
                            "DD.MMM.YYYY"
                          ) ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        "Total Orders":
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        "Total Sales": Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            )
                          : "0.00",
                        "Avg. Ticket Price":
                          Number(item?.total_atp) === 0
                            ? "0.00"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ),
                      }
                );
              }

              channel_summary =
                period_record === "day"
                  ? {
                      "Sales Date": "SUMMARY",
                      "Store Name": "Not Available",
                      "Total Orders": Number(
                        Number(totalOrders ?? 0)
                      ).toLocaleString(),
                      "Total Sales": number_format_without_currency(
                        Number(totalSales ?? 0)
                      ),
                      "Avg. Ticket Price": Number(
                        !isNaN(totalSales / totalOrders)
                      )
                        ? number_format_without_currency(
                            Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                          )
                        : "0.00",
                    }
                  : period_record === "week"
                  ? {
                      "Week Number": "SUMMARY",
                      "Store Name": "Not Available",
                      "Total Orders": Number(
                        Number(totalOrders ?? 0)
                      ).toLocaleString(),
                      "Total Sales": number_format_without_currency(
                        Number(totalSales ?? 0)
                      ),
                      "Avg. Ticket Price": Number(
                        !isNaN(totalSales / totalOrders)
                      )
                        ? number_format_without_currency(
                            Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                          )
                        : "0.00",
                    }
                  : period_record === "month"
                  ? {
                      Month: "SUMMARY",
                      "Store Name": "Not Available",
                      "Total Orders": Number(
                        Number(totalOrders ?? 0)
                      ).toLocaleString(),
                      "Total Sales": number_format_without_currency(
                        Number(totalSales ?? 0)
                      ),
                      "Avg. Ticket Price": Number(
                        !isNaN(totalSales / totalOrders)
                      )
                        ? number_format_without_currency(
                            Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                          )
                        : "0.00",
                    }
                  : period_record === "year"
                  ? {
                      Year: "SUMMARY",
                      "Store Name": "Not Available",
                      "Total Orders": Number(
                        Number(totalOrders ?? 0)
                      ).toLocaleString(),
                      "Total Sales": number_format_without_currency(
                        Number(totalSales ?? 0)
                      ),
                      "Avg. Ticket Price": Number(
                        !isNaN(totalSales / totalOrders)
                      )
                        ? number_format_without_currency(
                            Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                          )
                        : "0.00",
                    }
                  : {
                      "Week Number": "SUMMARY",
                      "Store Name": "Not Available",
                      "Total Orders": Number(
                        Number(totalOrders ?? 0)
                      ).toLocaleString(),
                      "Total Sales": number_format_without_currency(
                        Number(totalSales ?? 0)
                      ),
                      "Avg. Ticket Price": Number(
                        !isNaN(totalSales / totalOrders)
                      )
                        ? number_format_without_currency(
                            Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                          )
                        : "0.00",
                    };

              channel_report.push(channel_summary);
            }

            state.export_consolidate_sales = channel_report;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      .addCase(channel_sales_report.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(channel_sales_report.fulfilled, (state, { payload }) => {
        try {
          let channel_report = payload?.is_empty_data
            ? state.channel_sales_data
            : [];

          let channel_summary = payload?.is_empty_data
            ? state.channel_sales_summary
            : [];

          let totalSales = 0 as any;
          let totalOrders = 0 as any;

          let pulseOrders = 0 as any;
          let pulseSales = 0 as any;

          let callcenterOrders = 0 as any;
          let callcenterSales = 0 as any;

          let onlineOrders = 0 as any;
          let onlineSales = 0 as any;

          let woltOrders = 0 as any;
          let woltSales = 0 as any;

          let current_month_record = {} as any;

          if (payload?.data?.length > 0) {
            if (payload?.is_empty_data) {
              channel_report.pop();

              current_month_record = channel_report[channel_report.length - 1];

              channel_report.pop();
            }

            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data[index];

              totalSales = Number(totalSales) + Number(item?.total_sales ?? 0);
              totalOrders =
                Number(totalOrders) + Number(item?.total_orders ?? 0);

              pulseOrders =
                Number(pulseOrders) + Number(item?.pulse_orders ?? 0);
              pulseSales = Number(pulseSales) + Number(item?.pulse_sales ?? 0);

              callcenterOrders =
                Number(callcenterOrders) +
                Number(item?.call_center_orders ?? 0);
              callcenterSales =
                Number(callcenterSales) + Number(item?.call_center_salas ?? 0);

              onlineOrders =
                Number(onlineOrders) + Number(item?.online_orders ?? 0);
              onlineSales =
                Number(onlineSales) + Number(item?.online_sales ?? 0);

              woltOrders = Number(woltOrders) + Number(item?.wolt_orders ?? 0);
              woltSales = Number(woltSales) + Number(item?.wolt_salas ?? 0);

              channel_report.push(
                item?.week_day
                  ? [
                      {
                        name:
                          moment(new Date(item?.sales_date)).format(
                            "DD.MMM.YYYY"
                          ) ?? "Not Available",
                        style: { backgroundColor: "#ffffff" },
                      },
                      {
                        name: item?.week_day?.trim() ?? "Not Available",
                        style: { backgroundColor: "#ffffff" },
                      },
                      {
                        name: "W " + item?.week_number ?? "Not Available",
                        alignment: "center",
                        style: { backgroundColor: "#ffffff" },
                      },
                      {
                        name:
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.total_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.pulse_orders))
                          ? Number(
                              Number(item?.pulse_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.call_center_orders))
                          ? Number(
                              Number(item?.call_center_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.online_orders))
                          ? Number(
                              Number(item?.online_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.wolt_orders))
                          ? Number(
                              Number(item?.wolt_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                    ]
                  : item?.week_number
                  ? [
                      {
                        name: "W " + item?.week_number ?? "Not Available",
                        alignment: "center",
                        style: { backgroundColor: "#ffffff" },
                        colSpan: 3,
                      },
                      {
                        name:
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.total_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.pulse_orders))
                          ? Number(
                              Number(item?.pulse_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.call_center_orders))
                          ? Number(
                              Number(item?.call_center_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.online_orders))
                          ? Number(
                              Number(item?.online_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.wolt_orders))
                          ? Number(
                              Number(item?.wolt_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                    ]
                  : item?.month_number
                  ? [
                      {
                        name:
                          months[item?.month_number - 1] +
                            " " +
                            new Date().getFullYear() ?? "Not Available",
                        alignment: "center",
                        style: { backgroundColor: "#ffffff" },
                        colSpan: 3,
                      },
                      {
                        name:
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.total_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.pulse_orders))
                          ? Number(
                              Number(item?.pulse_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.call_center_orders))
                          ? Number(
                              Number(item?.call_center_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.online_orders))
                          ? Number(
                              Number(item?.online_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.wolt_orders))
                          ? Number(
                              Number(item?.wolt_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                    ]
                  : item?.year_number
                  ? [
                      {
                        name: item?.year_number ?? "Not Available",
                        alignment: "center",
                        style: { backgroundColor: "#ffffff" },
                        colSpan: 3,
                      },
                      {
                        name:
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.total_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.pulse_orders))
                          ? Number(
                              Number(item?.pulse_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.call_center_orders))
                          ? Number(
                              Number(item?.call_center_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.online_orders))
                          ? Number(
                              Number(item?.online_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.wolt_orders))
                          ? Number(
                              Number(item?.wolt_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                    ]
                  : [
                      {
                        name:
                          moment(new Date(item?.sales_date)).format(
                            "DD.MMM.YYYY"
                          ) ?? "Not Available",
                        style: { backgroundColor: "#ffffff" },
                      },
                      {
                        name: item?.week_day?.trim() ?? "Not Available",
                        style: { backgroundColor: "#ffffff" },
                      },
                      {
                        name: "W " + item?.week_number ?? "Not Available",
                        alignment: "center",
                        style: { backgroundColor: "#ffffff" },
                      },
                      {
                        name:
                          Number(item?.total_orders) === 0
                            ? "0"
                            : Number(
                                Number(item?.total_orders ?? 0)
                              ).toLocaleString(),
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.total_sales))
                          ? number_format_without_currency(
                              Number(item?.total_sales ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.total_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.total_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffffcb", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.pulse_orders))
                          ? Number(
                              Number(item?.pulse_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.pulse_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.pulse_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.pulse_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ccccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.call_center_orders))
                          ? Number(
                              Number(item?.call_center_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.call_center_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.call_center_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.call_center_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#bae190", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.online_orders))
                          ? Number(
                              Number(item?.online_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_sales ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.online_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.online_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.online_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#ffccff", color: "#000" },
                      },
                      {
                        name: Number(!isNaN(item?.wolt_orders))
                          ? Number(
                              Number(item?.wolt_orders ?? 0)
                            ).toLocaleString()
                          : "0",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_orders_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_orders_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_salas) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_salas ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_sales_percent) === 0
                            ? "0.00 %"
                            : Math.round(
                                Number(item?.wolt_sales_percent ?? 0)
                              ) + " %",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                      {
                        name:
                          Number(item?.wolt_atp) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_atp ?? 0)
                              ) + " ₼",
                        alignment: "center",
                        style: { backgroundColor: "#79dcff", color: "#000" },
                      },
                    ]
              );
            }

            if (!payload?.is_empty_data) {
              channel_summary = [
                {
                  name: "SUMMARY",
                  colSpan: 3,
                  alignment: "center",
                  style: {
                    backgroundColor: "#0078ac",
                    color: "#fff",
                    fontWeight: 900,
                  },
                },
                {
                  name: Number(Number(totalOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffffcb",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(totalSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffffcb",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(totalSales / totalOrders))
                    ? number_format_without_currency(
                        Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffffcb",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(pulseOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(pulseOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(pulseSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(pulseSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(pulseSales / pulseOrders))
                    ? number_format_without_currency(
                        Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(callcenterOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(callcenterOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(callcenterOrders ?? 0) /
                            Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ) + " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(callcenterSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(callcenterSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(callcenterSales / callcenterOrders))
                    ? number_format_without_currency(
                        Number(callcenterSales ?? 0) /
                          Number(callcenterOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(onlineOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(onlineOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(onlineSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(onlineSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(onlineSales / onlineOrders))
                    ? number_format_without_currency(
                        Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(woltOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(woltOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(woltSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(woltSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(woltSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(woltSales / woltOrders))
                    ? number_format_without_currency(
                        Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
              ];

              channel_report.push([
                {
                  name: "SUMMARY",
                  colSpan: 3,
                  alignment: "center",
                  style: {
                    backgroundColor: "#0078ac",
                    color: "#fff",
                    fontWeight: 900,
                  },
                },
                {
                  name: Number(Number(totalOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffffcb",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(totalSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffffcb",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(totalSales / totalOrders))
                    ? number_format_without_currency(
                        Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffffcb",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(pulseOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(pulseOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(pulseSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(pulseSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(pulseSales / pulseOrders))
                    ? number_format_without_currency(
                        Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ccccfe",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(callcenterOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(callcenterOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(callcenterOrders ?? 0) /
                            Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ) + " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(callcenterSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(callcenterSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(callcenterSales / callcenterOrders))
                    ? number_format_without_currency(
                        Number(callcenterSales ?? 0) /
                          Number(callcenterOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#bbe092",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(onlineOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(onlineOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(onlineSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(onlineSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(onlineSales / onlineOrders))
                    ? number_format_without_currency(
                        Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#ffccff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(Number(woltOrders ?? 0)).toLocaleString(),
                  alignment: "center",
                  style: {
                    backgroundColor: "#79dcff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(woltOrders / totalOrders))
                    ? Math.round(
                        Number(
                          Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#79dcff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name:
                    number_format_without_currency(Number(woltSales ?? 0)) +
                    " ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#79dcff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(woltSales / totalSales))
                    ? Math.round(
                        Number(
                          Number(woltSales ?? 0) / Number(totalSales ?? 0)
                        ) * 100
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                  style: {
                    backgroundColor: "#79dcff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
                {
                  name: Number(!isNaN(woltSales / woltOrders))
                    ? number_format_without_currency(
                        Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                  style: {
                    backgroundColor: "#79dcff",
                    fontWeight: 900,
                    color: "#000",
                  },
                },
              ]);
            }

            if (payload?.is_empty_data) {
              channel_report.push(current_month_record);
              channel_report.push(channel_summary);
            }
          }

          state.channel_sales_data = channel_report;
          state.channel_sales = payload?.data?.data;
          state.channel_sales_summary = channel_summary;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_channel_sales_report.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_channel_sales_report.fulfilled, (state, { payload }) => {
        try {
          let channel_report = [] as any;
          let channel_summary = [] as any;

          let totalSales = 0 as any;
          let totalOrders = 0 as any;

          let pulseOrders = 0 as any;
          let pulseSales = 0 as any;

          let callcenterOrders = 0 as any;
          let callcenterSales = 0 as any;

          let onlineOrders = 0 as any;
          let onlineSales = 0 as any;

          let woltOrders = 0 as any;
          let woltSales = 0 as any;

          let period_record = "day" as any;

          if (payload?.data?.length > 0) {
            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data[index];

              totalSales = Number(totalSales) + Number(item?.total_sales ?? 0);
              totalOrders =
                Number(totalOrders) + Number(item?.total_orders ?? 0);

              pulseOrders =
                Number(pulseOrders) + Number(item?.pulse_orders ?? 0);
              pulseSales = Number(pulseSales) + Number(item?.pulse_sales ?? 0);

              callcenterOrders =
                Number(callcenterOrders) +
                Number(item?.call_center_orders ?? 0);
              callcenterSales =
                Number(callcenterSales) + Number(item?.call_center_salas ?? 0);

              onlineOrders =
                Number(onlineOrders) + Number(item?.online_orders ?? 0);
              onlineSales =
                Number(onlineSales) + Number(item?.online_sales ?? 0);

              woltOrders = Number(woltOrders) + Number(item?.wolt_orders ?? 0);
              woltSales = Number(woltSales) + Number(item?.wolt_salas ?? 0);

              period_record = item?.week_day
                ? "day"
                : item?.week_number
                ? "weeek"
                : item?.month_number
                ? "month"
                : item?.year_number
                ? "year"
                : "day";

              channel_report.push(
                item?.week_day
                  ? {
                      "Sales Date":
                        moment(new Date(item?.sales_date)).format(
                          "DD.MMM.YYYY"
                        ) ?? "Not Available",
                      "Week Day": item?.week_day?.trim() ?? "Not Available",
                      "Week Number":
                        "W " + item?.week_number ?? "Not Available",
                      "Total Orders":
                        Number(item?.total_orders) === 0
                          ? "0"
                          : Number(
                              Number(item?.total_orders ?? 0)
                            ).toLocaleString(),
                      "Total Sales": Number(!isNaN(item?.total_sales))
                        ? number_format_without_currency(
                            Number(item?.total_sales ?? 0)
                          )
                        : "0.00",
                      "Avg. Ticket Price":
                        Number(item?.total_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.total_atp ?? 0)
                            ),
                      "Pulse Orders": Number(!isNaN(item?.pulse_orders))
                        ? Number(
                            Number(item?.pulse_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Pulse Orders":
                        Number(item?.pulse_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.pulse_orders_percent ?? 0)
                            ) + " %",
                      "Pulse Sales":
                        Number(item?.pulse_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_sales ?? 0)
                            ),
                      "% of Pulse Sales":
                        Number(item?.pulse_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.pulse_sales_percent ?? 0)) +
                            " %",
                      "Pulse Avg. Ticket Price":
                        Number(item?.pulse_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_atp ?? 0)
                            ),
                      "Callcenter Orders": Number(
                        !isNaN(item?.call_center_orders)
                      )
                        ? Number(
                            Number(item?.call_center_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Callcenter Orders":
                        Number(item?.call_center_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.call_center_orders_percent ?? 0)
                            ) + " %",
                      "Callcenter Sales":
                        Number(item?.call_center_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_salas ?? 0)
                            ),
                      "% of Callcenter Sales":
                        Number(item?.call_center_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.call_center_sales_percent ?? 0)
                            ) + " %",
                      "Callcenter Avg. Ticket Price":
                        Number(item?.call_center_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_atp ?? 0)
                            ),
                      "Online Orders": Number(!isNaN(item?.online_orders))
                        ? Number(
                            Number(item?.online_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Online Orders":
                        Number(item?.online_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.online_orders_percent ?? 0)
                            ) + " %",
                      "Online Sales":
                        Number(item?.online_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_sales ?? 0)
                            ),
                      "% of Online Sales":
                        Number(item?.online_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.online_sales_percent ?? 0)
                            ) + " %",
                      "Online Avg. Ticket Price":
                        Number(item?.online_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_atp ?? 0)
                            ),
                      "Wolt Orders": Number(!isNaN(item?.wolt_orders))
                        ? Number(
                            Number(item?.wolt_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Wolt Orders":
                        Number(item?.wolt_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.wolt_orders_percent ?? 0)
                            ) + " %",
                      "Wolt Sales":
                        Number(item?.wolt_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_salas ?? 0)
                            ),
                      "% of Wolt Sales":
                        Number(item?.wolt_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.wolt_sales_percent ?? 0)) +
                            " %",
                      "Wolt Avg. Ticket Price":
                        Number(item?.wolt_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_atp ?? 0)
                            ),
                    }
                  : item?.week_number
                  ? {
                      "Week Number":
                        "W " + item?.week_number ?? "Not Available",
                      "Total Orders":
                        Number(item?.total_orders) === 0
                          ? "0"
                          : Number(
                              Number(item?.total_orders ?? 0)
                            ).toLocaleString(),
                      "Total Sales": Number(!isNaN(item?.total_sales))
                        ? number_format_without_currency(
                            Number(item?.total_sales ?? 0)
                          )
                        : "0.00",
                      "Avg. Ticket Price":
                        Number(item?.total_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.total_atp ?? 0)
                            ),
                      "Pulse Orders": Number(!isNaN(item?.pulse_orders))
                        ? Number(
                            Number(item?.pulse_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Pulse Orders":
                        Number(item?.pulse_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.pulse_orders_percent ?? 0)
                            ) + " %",
                      "Pulse Sales":
                        Number(item?.pulse_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_sales ?? 0)
                            ),
                      "% of Pulse Sales":
                        Number(item?.pulse_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.pulse_sales_percent ?? 0)) +
                            " %",
                      "Pulse Avg. Ticket Price":
                        Number(item?.pulse_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_atp ?? 0)
                            ),
                      "Callcenter Orders": Number(
                        !isNaN(item?.call_center_orders)
                      )
                        ? Number(
                            Number(item?.call_center_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Callcenter Orders":
                        Number(item?.call_center_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.call_center_orders_percent ?? 0)
                            ) + " %",
                      "Callcenter Sales":
                        Number(item?.call_center_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_salas ?? 0)
                            ),
                      "% of Callcenter Sales":
                        Number(item?.call_center_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.call_center_sales_percent ?? 0)
                            ) + " %",
                      "Callcenter Avg. Ticket Price":
                        Number(item?.call_center_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_atp ?? 0)
                            ),
                      "Online Orders": Number(!isNaN(item?.online_orders))
                        ? Number(
                            Number(item?.online_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Online Orders":
                        Number(item?.online_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.online_orders_percent ?? 0)
                            ) + " %",
                      "Online Sales":
                        Number(item?.online_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_sales ?? 0)
                            ),
                      "% of Online Sales":
                        Number(item?.online_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.online_sales_percent ?? 0)
                            ) + " %",
                      "Online Avg. Ticket Price":
                        Number(item?.online_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_atp ?? 0)
                            ),
                      "Wolt Orders": Number(!isNaN(item?.wolt_orders))
                        ? Number(
                            Number(item?.wolt_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Wolt Orders":
                        Number(item?.wolt_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.wolt_orders_percent ?? 0)
                            ) + " %",
                      "Wolt Sales":
                        Number(item?.wolt_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_salas ?? 0)
                            ),
                      "% of Wolt Sales":
                        Number(item?.wolt_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.wolt_sales_percent ?? 0)) +
                            " %",
                      "Wolt Avg. Ticket Price":
                        Number(item?.wolt_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_atp ?? 0)
                            ),
                    }
                  : item?.month_number
                  ? {
                      Month:
                        months[item?.month_number - 1] +
                          " " +
                          new Date().getFullYear() ?? "Not Available",
                      "Total Orders":
                        Number(item?.total_orders) === 0
                          ? "0"
                          : Number(
                              Number(item?.total_orders ?? 0)
                            ).toLocaleString(),
                      "Total Sales": Number(!isNaN(item?.total_sales))
                        ? number_format_without_currency(
                            Number(item?.total_sales ?? 0)
                          )
                        : "0.00",
                      "Avg. Ticket Price":
                        Number(item?.total_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.total_atp ?? 0)
                            ),
                      "Pulse Orders": Number(!isNaN(item?.pulse_orders))
                        ? Number(
                            Number(item?.pulse_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Pulse Orders":
                        Number(item?.pulse_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.pulse_orders_percent ?? 0)
                            ) + " %",
                      "Pulse Sales":
                        Number(item?.pulse_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_sales ?? 0)
                            ),
                      "% of Pulse Sales":
                        Number(item?.pulse_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.pulse_sales_percent ?? 0)) +
                            " %",
                      "Pulse Avg. Ticket Price":
                        Number(item?.pulse_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_atp ?? 0)
                            ),
                      "Callcenter Orders": Number(
                        !isNaN(item?.call_center_orders)
                      )
                        ? Number(
                            Number(item?.call_center_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Callcenter Orders":
                        Number(item?.call_center_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.call_center_orders_percent ?? 0)
                            ) + " %",
                      "Callcenter Sales":
                        Number(item?.call_center_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_salas ?? 0)
                            ),
                      "% of Callcenter Sales":
                        Number(item?.call_center_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.call_center_sales_percent ?? 0)
                            ) + " %",
                      "Callcenter Avg. Ticket Price":
                        Number(item?.call_center_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_atp ?? 0)
                            ),
                      "Online Orders": Number(!isNaN(item?.online_orders))
                        ? Number(
                            Number(item?.online_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Online Orders":
                        Number(item?.online_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.online_orders_percent ?? 0)
                            ) + " %",
                      "Online Sales":
                        Number(item?.online_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_sales ?? 0)
                            ),
                      "% of Online Sales":
                        Number(item?.online_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.online_sales_percent ?? 0)
                            ) + " %",
                      "Online Avg. Ticket Price":
                        Number(item?.online_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_atp ?? 0)
                            ),
                      "Wolt Orders": Number(!isNaN(item?.wolt_orders))
                        ? Number(
                            Number(item?.wolt_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Wolt Orders":
                        Number(item?.wolt_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.wolt_orders_percent ?? 0)
                            ) + " %",
                      "Wolt Sales":
                        Number(item?.wolt_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_salas ?? 0)
                            ),
                      "% of Wolt Sales":
                        Number(item?.wolt_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.wolt_sales_percent ?? 0)) +
                            " %",
                      "Wolt Avg. Ticket Price":
                        Number(item?.wolt_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_atp ?? 0)
                            ),
                    }
                  : item?.year_number
                  ? {
                      Year: item?.year_number ?? "Not Available",
                      "Total Orders":
                        Number(item?.total_orders) === 0
                          ? "0"
                          : Number(
                              Number(item?.total_orders ?? 0)
                            ).toLocaleString(),
                      "Total Sales": Number(!isNaN(item?.total_sales))
                        ? number_format_without_currency(
                            Number(item?.total_sales ?? 0)
                          )
                        : "0.00",
                      "Avg. Ticket Price":
                        Number(item?.total_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.total_atp ?? 0)
                            ),
                      "Pulse Orders": Number(!isNaN(item?.pulse_orders))
                        ? Number(
                            Number(item?.pulse_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Pulse Orders":
                        Number(item?.pulse_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.pulse_orders_percent ?? 0)
                            ) + " %",
                      "Pulse Sales":
                        Number(item?.pulse_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_sales ?? 0)
                            ),
                      "% of Pulse Sales":
                        Number(item?.pulse_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.pulse_sales_percent ?? 0)) +
                            " %",
                      "Pulse Avg. Ticket Price":
                        Number(item?.pulse_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_atp ?? 0)
                            ),
                      "Callcenter Orders": Number(
                        !isNaN(item?.call_center_orders)
                      )
                        ? Number(
                            Number(item?.call_center_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Callcenter Orders":
                        Number(item?.call_center_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.call_center_orders_percent ?? 0)
                            ) + " %",
                      "Callcenter Sales":
                        Number(item?.call_center_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_salas ?? 0)
                            ),
                      "% of Callcenter Sales":
                        Number(item?.call_center_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.call_center_sales_percent ?? 0)
                            ) + " %",
                      "Callcenter Avg. Ticket Price":
                        Number(item?.call_center_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_atp ?? 0)
                            ),
                      "Online Orders": Number(!isNaN(item?.online_orders))
                        ? Number(
                            Number(item?.online_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Online Orders":
                        Number(item?.online_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.online_orders_percent ?? 0)
                            ) + " %",
                      "Online Sales":
                        Number(item?.online_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_sales ?? 0)
                            ),
                      "% of Online Sales":
                        Number(item?.online_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.online_sales_percent ?? 0)
                            ) + " %",
                      "Online Avg. Ticket Price":
                        Number(item?.online_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_atp ?? 0)
                            ),
                      "Wolt Orders": Number(!isNaN(item?.wolt_orders))
                        ? Number(
                            Number(item?.wolt_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Wolt Orders":
                        Number(item?.wolt_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.wolt_orders_percent ?? 0)
                            ) + " %",
                      "Wolt Sales":
                        Number(item?.wolt_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_salas ?? 0)
                            ),
                      "% of Wolt Sales":
                        Number(item?.wolt_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.wolt_sales_percent ?? 0)) +
                            " %",
                      "Wolt Avg. Ticket Price":
                        Number(item?.wolt_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_atp ?? 0)
                            ),
                    }
                  : {
                      "Sales Date":
                        moment(new Date(item?.sales_date)).format(
                          "DD.MMM.YYYY"
                        ) ?? "Not Available",
                      "Week Day": item?.week_day?.trim() ?? "Not Available",
                      "Week Number":
                        "W " + item?.week_number ?? "Not Available",
                      "Total Orders":
                        Number(item?.total_orders) === 0
                          ? "0"
                          : Number(
                              Number(item?.total_orders ?? 0)
                            ).toLocaleString(),
                      "Total Sales": Number(!isNaN(item?.total_sales))
                        ? number_format_without_currency(
                            Number(item?.total_sales ?? 0)
                          )
                        : "0.00",
                      "Avg. Ticket Price":
                        Number(item?.total_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.total_atp ?? 0)
                            ),
                      "Pulse Orders": Number(!isNaN(item?.pulse_orders))
                        ? Number(
                            Number(item?.pulse_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Pulse Orders":
                        Number(item?.pulse_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.pulse_orders_percent ?? 0)
                            ) + " %",
                      "Pulse Sales":
                        Number(item?.pulse_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_sales ?? 0)
                            ),
                      "% of Pulse Sales":
                        Number(item?.pulse_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.pulse_sales_percent ?? 0)) +
                            " %",
                      "Pulse Avg. Ticket Price":
                        Number(item?.pulse_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.pulse_atp ?? 0)
                            ),
                      "Callcenter Orders": Number(
                        !isNaN(item?.call_center_orders)
                      )
                        ? Number(
                            Number(item?.call_center_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Callcenter Orders":
                        Number(item?.call_center_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.call_center_orders_percent ?? 0)
                            ) + " %",
                      "Callcenter Sales":
                        Number(item?.call_center_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_salas ?? 0)
                            ),
                      "% of Callcenter Sales":
                        Number(item?.call_center_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.call_center_sales_percent ?? 0)
                            ) + " %",
                      "Callcenter Avg. Ticket Price":
                        Number(item?.call_center_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.call_center_atp ?? 0)
                            ),
                      "Online Orders": Number(!isNaN(item?.online_orders))
                        ? Number(
                            Number(item?.online_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Online Orders":
                        Number(item?.online_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.online_orders_percent ?? 0)
                            ) + " %",
                      "Online Sales":
                        Number(item?.online_sales) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_sales ?? 0)
                            ),
                      "% of Online Sales":
                        Number(item?.online_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(
                              Number(item?.online_sales_percent ?? 0)
                            ) + " %",
                      "Online Avg. Ticket Price":
                        Number(item?.online_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.online_atp ?? 0)
                            ),
                      "Wolt Orders": Number(!isNaN(item?.wolt_orders))
                        ? Number(
                            Number(item?.wolt_orders ?? 0)
                          ).toLocaleString()
                        : "0",
                      "% of Wolt Orders":
                        Number(item?.wolt_orders_percent) === 0
                          ? "0.00 %"
                          : number_format_without_currency(
                              Number(item?.wolt_orders_percent ?? 0)
                            ) + " %",
                      "Wolt Sales":
                        Number(item?.wolt_salas) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_salas ?? 0)
                            ),
                      "% of Wolt Sales":
                        Number(item?.wolt_sales_percent) === 0
                          ? "0.00 %"
                          : Math.round(Number(item?.wolt_sales_percent ?? 0)) +
                            " %",
                      "Wolt Avg. Ticket Price":
                        Number(item?.wolt_atp) === 0
                          ? "0.00"
                          : number_format_without_currency(
                              Number(item?.wolt_atp ?? 0)
                            ),
                    }
              );
            }

            channel_summary =
              period_record === "day"
                ? {
                    "Sales Date": "Not Available",
                    "Week Day": "Not Available",
                    "Week Number": "SUMMARY",
                    "Total Orders": Number(
                      Number(totalOrders ?? 0)
                    ).toLocaleString(),
                    "Total Sales": number_format_without_currency(
                      Number(totalSales ?? 0)
                    ),
                    "Avg. Ticket Price": Number(
                      !isNaN(totalSales / totalOrders)
                    )
                      ? number_format_without_currency(
                          Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                        )
                      : "0.00",

                    "Pulse Orders": Number(
                      Number(pulseOrders ?? 0)
                    ).toLocaleString(),
                    "% of Pulse Orders": Number(
                      !isNaN(pulseOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Sales": number_format_without_currency(
                      Number(pulseSales ?? 0)
                    ),
                    "% of Pulse Sales": Number(!isNaN(pulseSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Avg. Ticket Price": Number(
                      !isNaN(pulseSales / pulseOrders)
                    )
                      ? number_format_without_currency(
                          Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                        )
                      : "0.00",

                    "Callcenter Orders": Number(
                      Number(callcenterOrders ?? 0)
                    ).toLocaleString(),
                    "% of Callcenter Orders": Number(
                      !isNaN(callcenterOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterOrders ?? 0) /
                              Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Sales": number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ),
                    "% of Callcenter Sales": Number(
                      !isNaN(callcenterSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterSales ?? 0) /
                              Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Avg. Ticket Price": Number(
                      !isNaN(callcenterSales / callcenterOrders)
                    )
                      ? number_format_without_currency(
                          Number(callcenterSales ?? 0) /
                            Number(callcenterOrders ?? 0)
                        )
                      : "0.00",

                    "Online Orders": Number(
                      Number(onlineOrders ?? 0)
                    ).toLocaleString(),
                    "% of Online Orders": Number(
                      !isNaN(onlineOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Sales": number_format_without_currency(
                      Number(onlineSales ?? 0)
                    ),
                    "% of Online Sales": Number(
                      !isNaN(onlineSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Avg. Ticket Price": Number(
                      !isNaN(onlineSales / onlineOrders)
                    )
                      ? number_format_without_currency(
                          Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                        )
                      : "0.00",

                    "Wolt Orders": Number(
                      Number(woltOrders ?? 0)
                    ).toLocaleString(),
                    "% of Wolt Orders": Number(!isNaN(woltOrders / totalOrders))
                      ? Math.round(
                          Number(
                            Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Sales": number_format_without_currency(
                      Number(woltSales ?? 0)
                    ),
                    "% of Wolt Sales": Number(!isNaN(woltSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(woltSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Avg. Ticket Price": Number(
                      !isNaN(woltSales / woltOrders)
                    )
                      ? number_format_without_currency(
                          Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                        )
                      : "0.00",
                  }
                : period_record === "week"
                ? {
                    "Week Number": "SUMMARY",
                    "Total Orders": Number(
                      Number(totalOrders ?? 0)
                    ).toLocaleString(),
                    "Total Sales": number_format_without_currency(
                      Number(totalSales ?? 0)
                    ),
                    "Avg. Ticket Price": Number(
                      !isNaN(totalSales / totalOrders)
                    )
                      ? number_format_without_currency(
                          Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                        )
                      : "0.00",

                    "Pulse Orders": Number(
                      Number(pulseOrders ?? 0)
                    ).toLocaleString(),
                    "% of Pulse Orders": Number(
                      !isNaN(pulseOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Sales": number_format_without_currency(
                      Number(pulseSales ?? 0)
                    ),
                    "% of Pulse Sales": Number(!isNaN(pulseSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Avg. Ticket Price": Number(
                      !isNaN(pulseSales / pulseOrders)
                    )
                      ? number_format_without_currency(
                          Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                        )
                      : "0.00",

                    "Callcenter Orders": Number(
                      Number(callcenterOrders ?? 0)
                    ).toLocaleString(),
                    "% of Callcenter Orders": Number(
                      !isNaN(callcenterOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterOrders ?? 0) /
                              Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Sales": number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ),
                    "% of Callcenter Sales": Number(
                      !isNaN(callcenterSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterSales ?? 0) /
                              Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Avg. Ticket Price": Number(
                      !isNaN(callcenterSales / callcenterOrders)
                    )
                      ? number_format_without_currency(
                          Number(callcenterSales ?? 0) /
                            Number(callcenterOrders ?? 0)
                        )
                      : "0.00",

                    "Online Orders": Number(
                      Number(onlineOrders ?? 0)
                    ).toLocaleString(),
                    "% of Online Orders": Number(
                      !isNaN(onlineOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Sales": number_format_without_currency(
                      Number(onlineSales ?? 0)
                    ),
                    "% of Online Sales": Number(
                      !isNaN(onlineSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Avg. Ticket Price": Number(
                      !isNaN(onlineSales / onlineOrders)
                    )
                      ? number_format_without_currency(
                          Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                        )
                      : "0.00",

                    "Wolt Orders": Number(
                      Number(woltOrders ?? 0)
                    ).toLocaleString(),
                    "% of Wolt Orders": Number(!isNaN(woltOrders / totalOrders))
                      ? Math.round(
                          Number(
                            Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Sales": number_format_without_currency(
                      Number(woltSales ?? 0)
                    ),
                    "% of Wolt Sales": Number(!isNaN(woltSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(woltSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Avg. Ticket Price": Number(
                      !isNaN(woltSales / woltOrders)
                    )
                      ? number_format_without_currency(
                          Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                        )
                      : "0.00",
                  }
                : period_record === "month"
                ? {
                    Month: "SUMMARY",
                    "Total Orders": Number(
                      Number(totalOrders ?? 0)
                    ).toLocaleString(),
                    "Total Sales": number_format_without_currency(
                      Number(totalSales ?? 0)
                    ),
                    "Avg. Ticket Price": Number(
                      !isNaN(totalSales / totalOrders)
                    )
                      ? number_format_without_currency(
                          Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                        )
                      : "0.00",

                    "Pulse Orders": Number(
                      Number(pulseOrders ?? 0)
                    ).toLocaleString(),
                    "% of Pulse Orders": Number(
                      !isNaN(pulseOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Sales": number_format_without_currency(
                      Number(pulseSales ?? 0)
                    ),
                    "% of Pulse Sales": Number(!isNaN(pulseSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Avg. Ticket Price": Number(
                      !isNaN(pulseSales / pulseOrders)
                    )
                      ? number_format_without_currency(
                          Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                        )
                      : "0.00",

                    "Callcenter Orders": Number(
                      Number(callcenterOrders ?? 0)
                    ).toLocaleString(),
                    "% of Callcenter Orders": Number(
                      !isNaN(callcenterOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterOrders ?? 0) /
                              Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Sales": number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ),
                    "% of Callcenter Sales": Number(
                      !isNaN(callcenterSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterSales ?? 0) /
                              Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Avg. Ticket Price": Number(
                      !isNaN(callcenterSales / callcenterOrders)
                    )
                      ? number_format_without_currency(
                          Number(callcenterSales ?? 0) /
                            Number(callcenterOrders ?? 0)
                        )
                      : "0.00",

                    "Online Orders": Number(
                      Number(onlineOrders ?? 0)
                    ).toLocaleString(),
                    "% of Online Orders": Number(
                      !isNaN(onlineOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Sales": number_format_without_currency(
                      Number(onlineSales ?? 0)
                    ),
                    "% of Online Sales": Number(
                      !isNaN(onlineSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Avg. Ticket Price": Number(
                      !isNaN(onlineSales / onlineOrders)
                    )
                      ? number_format_without_currency(
                          Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                        )
                      : "0.00",

                    "Wolt Orders": Number(
                      Number(woltOrders ?? 0)
                    ).toLocaleString(),
                    "% of Wolt Orders": Number(!isNaN(woltOrders / totalOrders))
                      ? Math.round(
                          Number(
                            Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Sales": number_format_without_currency(
                      Number(woltSales ?? 0)
                    ),
                    "% of Wolt Sales": Number(!isNaN(woltSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(woltSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Avg. Ticket Price": Number(
                      !isNaN(woltSales / woltOrders)
                    )
                      ? number_format_without_currency(
                          Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                        )
                      : "0.00",
                  }
                : period_record === "year"
                ? {
                    Year: "SUMMARY",
                    "Total Orders": Number(
                      Number(totalOrders ?? 0)
                    ).toLocaleString(),
                    "Total Sales": number_format_without_currency(
                      Number(totalSales ?? 0)
                    ),
                    "Avg. Ticket Price": Number(
                      !isNaN(totalSales / totalOrders)
                    )
                      ? number_format_without_currency(
                          Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                        )
                      : "0.00",

                    "Pulse Orders": Number(
                      Number(pulseOrders ?? 0)
                    ).toLocaleString(),
                    "% of Pulse Orders": Number(
                      !isNaN(pulseOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Sales": number_format_without_currency(
                      Number(pulseSales ?? 0)
                    ),
                    "% of Pulse Sales": Number(!isNaN(pulseSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Avg. Ticket Price": Number(
                      !isNaN(pulseSales / pulseOrders)
                    )
                      ? number_format_without_currency(
                          Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                        )
                      : "0.00",

                    "Callcenter Orders": Number(
                      Number(callcenterOrders ?? 0)
                    ).toLocaleString(),
                    "% of Callcenter Orders": Number(
                      !isNaN(callcenterOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterOrders ?? 0) /
                              Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Sales": number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ),
                    "% of Callcenter Sales": Number(
                      !isNaN(callcenterSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterSales ?? 0) /
                              Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Avg. Ticket Price": Number(
                      !isNaN(callcenterSales / callcenterOrders)
                    )
                      ? number_format_without_currency(
                          Number(callcenterSales ?? 0) /
                            Number(callcenterOrders ?? 0)
                        )
                      : "0.00",

                    "Online Orders": Number(
                      Number(onlineOrders ?? 0)
                    ).toLocaleString(),
                    "% of Online Orders": Number(
                      !isNaN(onlineOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Sales": number_format_without_currency(
                      Number(onlineSales ?? 0)
                    ),
                    "% of Online Sales": Number(
                      !isNaN(onlineSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Avg. Ticket Price": Number(
                      !isNaN(onlineSales / onlineOrders)
                    )
                      ? number_format_without_currency(
                          Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                        )
                      : "0.00",

                    "Wolt Orders": Number(
                      Number(woltOrders ?? 0)
                    ).toLocaleString(),
                    "% of Wolt Orders": Number(!isNaN(woltOrders / totalOrders))
                      ? Math.round(
                          Number(
                            Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Sales": number_format_without_currency(
                      Number(woltSales ?? 0)
                    ),
                    "% of Wolt Sales": Number(!isNaN(woltSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(woltSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Avg. Ticket Price": Number(
                      !isNaN(woltSales / woltOrders)
                    )
                      ? number_format_without_currency(
                          Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                        )
                      : "0.00",
                  }
                : {
                    "Week Number": "SUMMARY",
                    "Total Orders": Number(
                      Number(totalOrders ?? 0)
                    ).toLocaleString(),
                    "Total Sales": number_format_without_currency(
                      Number(totalSales ?? 0)
                    ),
                    "Avg. Ticket Price": Number(
                      !isNaN(totalSales / totalOrders)
                    )
                      ? number_format_without_currency(
                          Number(totalSales ?? 0) / Number(totalOrders ?? 0)
                        )
                      : "0.00",

                    "Pulse Orders": Number(
                      Number(pulseOrders ?? 0)
                    ).toLocaleString(),
                    "% of Pulse Orders": Number(
                      !isNaN(pulseOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(pulseOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Sales": number_format_without_currency(
                      Number(pulseSales ?? 0)
                    ),
                    "% of Pulse Sales": Number(!isNaN(pulseSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(pulseSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Pulse Avg. Ticket Price": Number(
                      !isNaN(pulseSales / pulseOrders)
                    )
                      ? number_format_without_currency(
                          Number(pulseSales ?? 0) / Number(pulseOrders ?? 0)
                        )
                      : "0.00",

                    "Callcenter Orders": Number(
                      Number(callcenterOrders ?? 0)
                    ).toLocaleString(),
                    "% of Callcenter Orders": Number(
                      !isNaN(callcenterOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterOrders ?? 0) /
                              Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Sales": number_format_without_currency(
                      Number(callcenterSales ?? 0)
                    ),
                    "% of Callcenter Sales": Number(
                      !isNaN(callcenterSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(callcenterSales ?? 0) /
                              Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Callcenter Avg. Ticket Price": Number(
                      !isNaN(callcenterSales / callcenterOrders)
                    )
                      ? number_format_without_currency(
                          Number(callcenterSales ?? 0) /
                            Number(callcenterOrders ?? 0)
                        )
                      : "0.00",

                    "Online Orders": Number(
                      Number(onlineOrders ?? 0)
                    ).toLocaleString(),
                    "% of Online Orders": Number(
                      !isNaN(onlineOrders / totalOrders)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Sales": number_format_without_currency(
                      Number(onlineSales ?? 0)
                    ),
                    "% of Online Sales": Number(
                      !isNaN(onlineSales / totalSales)
                    )
                      ? Math.round(
                          Number(
                            Number(onlineSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Online Avg. Ticket Price": Number(
                      !isNaN(onlineSales / onlineOrders)
                    )
                      ? number_format_without_currency(
                          Number(onlineSales ?? 0) / Number(onlineOrders ?? 0)
                        )
                      : "0.00",

                    "Wolt Orders": Number(
                      Number(woltOrders ?? 0)
                    ).toLocaleString(),
                    "% of Wolt Orders": Number(!isNaN(woltOrders / totalOrders))
                      ? Math.round(
                          Number(
                            Number(woltOrders ?? 0) / Number(totalOrders ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Sales": number_format_without_currency(
                      Number(woltSales ?? 0)
                    ),
                    "% of Wolt Sales": Number(!isNaN(woltSales / totalSales))
                      ? Math.round(
                          Number(
                            Number(woltSales ?? 0) / Number(totalSales ?? 0)
                          ) * 100
                        ) + " %"
                      : "0.00 %",
                    "Wolt Avg. Ticket Price": Number(
                      !isNaN(woltSales / woltOrders)
                    )
                      ? number_format_without_currency(
                          Number(woltSales ?? 0) / Number(woltOrders ?? 0)
                        )
                      : "0.00",
                  };

            channel_report.push(channel_summary);
          }

          state.export_channel_sales = channel_report;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(get_latest_sales_records.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_latest_sales_records.fulfilled, (state, { payload }) => {
        state.activity = false;
      })

      .addCase(get_latest_sales_store_records.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(
        get_latest_sales_store_records.fulfilled,
        (state, { payload }) => {
          state.activity = false;
        }
      )

      .addCase(get_sales_dashboard.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_sales_dashboard.fulfilled, (state, { payload }) => {
        try {
          let total_orders = [] as any;
          let total_sales = [] as any;
          let total_atp = [] as any;
          let sales_orders_chart_data = [] as any;

          let callcenter_orders = [] as any;
          let olo_orders = [] as any;
          let pulse_orders = [] as any;
          let wolt_orders = [] as any;
          let callcenter_olo_pulse_orders_chart_data = [] as any;

          let callcenter_sales = [] as any;
          let olo_sales = [] as any;
          let pulse_sales = [] as any;
          let wolt_sales = [] as any;
          let callcenter_olo_pulse_sales_chart_data = [] as any;

          let callcenter_atp = [] as any;
          let olo_atp = [] as any;
          let pulse_atp = [] as any;
          let wolt_atp = [] as any;
          let callcenter_olo_pulse_atp_chart_data = [] as any;

          let labels = [] as any;

          if (payload?.data?.length > 0) {
            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data[index];

              total_orders.push(item?.total_orders);
              total_sales.push(item?.total_sales);
              total_atp.push(item?.total_atp);

              olo_orders.push(item?.online_orders);
              callcenter_orders.push(item?.call_center_orders);
              pulse_orders.push(item?.pulse_orders);
              wolt_orders.push(item?.wolt_orders);

              olo_sales.push(item?.online_sales);
              callcenter_sales.push(item?.call_center_salas);
              pulse_sales.push(item?.pulse_sales);
              wolt_sales.push(item?.wolt_salas);

              olo_atp.push(item?.online_atp);
              callcenter_atp.push(item?.call_center_atp);
              pulse_atp.push(item?.pulse_atp);
              wolt_atp.push(item?.wolt_atp);

              labels.push(
                item?.week_day
                  ? moment(new Date(item?.sales_date)).format("DD.MMM.YYYY")
                  : item?.week_number
                  ? "W " + item?.week_number
                  : item?.month_number
                  ? months[item?.month_number - 1] + " " + item?.year_number
                  : item?.year_number
                  ? item?.year_number
                  : "Not Available"
              );
            }
          }

          sales_orders_chart_data.push(total_orders);
          sales_orders_chart_data.push(total_sales);
          sales_orders_chart_data.push(total_atp);
          sales_orders_chart_data.push(labels);

          callcenter_olo_pulse_orders_chart_data.push(olo_orders);
          callcenter_olo_pulse_orders_chart_data.push(callcenter_orders);
          callcenter_olo_pulse_orders_chart_data.push(pulse_orders);
          callcenter_olo_pulse_orders_chart_data.push(wolt_orders);
          callcenter_olo_pulse_orders_chart_data.push(labels);

          callcenter_olo_pulse_sales_chart_data.push(olo_sales);
          callcenter_olo_pulse_sales_chart_data.push(callcenter_sales);
          callcenter_olo_pulse_sales_chart_data.push(pulse_sales);
          callcenter_olo_pulse_sales_chart_data.push(wolt_sales);
          callcenter_olo_pulse_sales_chart_data.push(labels);

          callcenter_olo_pulse_atp_chart_data.push(olo_atp);
          callcenter_olo_pulse_atp_chart_data.push(callcenter_atp);
          callcenter_olo_pulse_atp_chart_data.push(pulse_atp);
          callcenter_olo_pulse_atp_chart_data.push(wolt_atp);
          callcenter_olo_pulse_atp_chart_data.push(labels);

          state.total_orders_total_sales_chart_data = sales_orders_chart_data;
          state.callcenter_orders_olo_orders_chart_data =
            callcenter_olo_pulse_orders_chart_data;
          state.callcenter_sales_olo_sales_chart_data =
            callcenter_olo_pulse_sales_chart_data;
          state.callcenter_atp_olo_atp_chart_data =
            callcenter_olo_pulse_atp_chart_data;

          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(consolidate_inventory_report.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(consolidate_inventory_report.fulfilled, (state, { payload }) => {
        try {
          let consolidate_report = [] as any;
          let actual_food = 0 as any;
          let ideal_food = 0 as any;
          let net_sales = 0 as any;

          payload?.data?.result?.sort(function (a: any, b: any) {
            var keyA = new Date(a.store_code),
              keyB = new Date(b.store_code);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          if (payload?.data?.result?.length > 0) {
            for (
              let index = 0;
              index < payload?.data?.result?.length;
              index++
            ) {
              const item = payload?.data?.result[index];
              actual_food = Number(actual_food) + Number(item?.food ?? 0);
              ideal_food = Number(ideal_food) + Number(item?.ifc ?? 0);
              net_sales = Number(net_sales) + Number(item?.net_sales ?? 0);

              consolidate_report.push([
                item?.store_name || "Not Available",
                {
                  name: Number(!isNaN(item?.food))
                    ? number_format_without_currency(Number(item?.food ?? 0)) +
                      " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.actual_food_per))
                    ? number_format_without_currency(
                        Number(item?.actual_food_per ?? 0)
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.ifc))
                    ? number_format_without_currency(Number(item?.ifc ?? 0)) +
                      " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.ideal_food_per))
                    ? number_format_without_currency(
                        Number(item?.ideal_food_per ?? 0)
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.actual_ideal_food_variance_per))
                    ? number_format_without_currency(
                        Number(item?.actual_ideal_food_variance_per ?? 0)
                      ) + " %"
                    : "0.00 %",
                  alignment: "center",
                },
              ]);
            }

            consolidate_report.push([
              "SUMMARY",
              {
                name: Number(!isNaN(actual_food))
                  ? number_format_without_currency(Number(actual_food)) + " ₼"
                  : "0.00 ₼",
                alignment: "center",
              },
              {
                name: Number(!isNaN(actual_food / net_sales))
                  ? number_format_without_currency(
                      Number(actual_food / net_sales)
                    ) + " %"
                  : "0.00 %",
                alignment: "center",
              },
              {
                name: Number(!isNaN(ideal_food))
                  ? number_format_without_currency(Number(ideal_food)) + " ₼"
                  : "0.00 ₼",
                alignment: "center",
              },
              {
                name: Number(!isNaN(ideal_food / net_sales))
                  ? number_format_without_currency(
                      Number(ideal_food / net_sales)
                    ) + " %"
                  : "0.00 %",
                alignment: "center",
              },
              {
                name:
                  Number(!isNaN(actual_food / net_sales)) &&
                  Number(!isNaN(ideal_food / net_sales))
                    ? number_format_without_currency(
                        Number(
                          Number(ideal_food / net_sales) -
                            Number(actual_food / net_sales)
                        )
                      ) + " %"
                    : "0.00 %",
                alignment: "center",
              },
            ]);
          }

          state.consolidate_inventory_data = consolidate_report;
          state.consolidate_inventory = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(get_cost_of_goods_sold.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_cost_of_goods_sold.fulfilled, (state, { payload }) => {
        try {
          let cogs = [] as any;
          let actual_food = 0 as any;
          let ideal_food = 0 as any;
          let net_sales = 0 as any;

          payload?.data.sort(function (a: any, b: any) {
            var keyA = new Date(a.store_code),
              keyB = new Date(b.store_code);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            actual_food = Number(actual_food) + Number(item?.food ?? 0);
            ideal_food = Number(ideal_food) + Number(item?.ifc ?? 0);
            net_sales = Number(net_sales) + Number(item?.net_sales ?? 0);

            cogs.push([
              {
                name: "SA" + item?.format_column || "Not Available",
                hidden: true,
              },
              moment(new Date(item?.system_date)).format("DD.MMM.YYYY") ||
                "Not Available",
              item?.name || "Not Available",
              {
                name:
                  Number(item?.food) === 0
                    ? "0.00 ₼"
                    : Number(!isNaN(item?.food))
                    ? number_format_without_currency(
                        Number(item?.food ?? 0.0)
                      ) + " ₼"
                    : "0.00 ₼",
                alignment: "center",
              },
              {
                name:
                  Number(item?.actual_food_per) === 0
                    ? "0.00 %"
                    : Number(!isNaN(item?.actual_food_per))
                    ? number_format_without_currency(
                        Number(item?.actual_food_per ?? 0.0)
                      ) + " %"
                    : "0.00 %",
                alignment: "center",
              },
              {
                name:
                  Number(item?.ifc) === 0
                    ? "0.00 ₼"
                    : Number(!isNaN(item?.ifc))
                    ? number_format_without_currency(Number(item?.ifc ?? 0.0)) +
                      " ₼"
                    : "0.00 ₼",
                alignment: "center",
              },
              {
                name:
                  Number(item?.ideal_food_per) === 0
                    ? "0.00 %"
                    : Number(!isNaN(item?.ideal_food_per))
                    ? number_format_without_currency(
                        Number(item?.ideal_food_per ?? 0.0)
                      ) + " %"
                    : "0.00 %",
                alignment: "center",
              },
              {
                name:
                  Number(item?.actual_ideal_food_variance_per) === 0
                    ? "0.00 %"
                    : Number(!isNaN(item?.actual_ideal_food_variance_per))
                    ? number_format_without_currency(
                        Number(item?.actual_ideal_food_variance_per ?? 0.0)
                      ) + " %"
                    : "0.00 %",
                alignment: "center",
              },
              // {
              //   name:
              //     Number(item?.percent_inventory) === 0
              //       ? "0.00 %"
              //       : Number(!isNaN(item?.percent_inventory))
              //       ? number_format_without_currency(
              //           Number(item?.percent_inventory ?? 0.0)
              //         ) + " %"
              //       : "0.00 %",
              //   alignment: "center",
              // },
            ]);
          }

          cogs.push([
            { name: "SUMMARY", alignment: "center", colSpan: 2 },
            {
              name: Number(!isNaN(actual_food))
                ? number_format_without_currency(Number(actual_food)) + " ₼"
                : "0.00 ₼",
              alignment: "center",
            },
            {
              name: Number(!isNaN(actual_food / net_sales))
                ? number_format_without_currency(
                    Number(actual_food / net_sales)
                  ) + " %"
                : "0.00 %",
              alignment: "center",
            },
            {
              name: Number(!isNaN(ideal_food))
                ? number_format_without_currency(Number(ideal_food)) + " ₼"
                : "0.00 ₼",
              alignment: "center",
            },
            {
              name: Number(!isNaN(ideal_food / net_sales))
                ? number_format_without_currency(
                    Number(ideal_food / net_sales)
                  ) + " %"
                : "0.00 %",
              alignment: "center",
            },
            {
              name:
                Number(!isNaN(actual_food / net_sales)) &&
                Number(!isNaN(ideal_food / net_sales))
                  ? number_format_without_currency(
                      Number(ideal_food / net_sales) -
                        Number(actual_food / net_sales)
                    ) + " %"
                  : "0.00 %",
              alignment: "center",
            },
            // {
            //   name: "-",
            //   alignment: "center",
            // },
          ]);

          state.cost_of_goods_sold_data = payload;
          state.cost_of_goods_sold = cogs;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_cost_of_goods_sold.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_cost_of_goods_sold.fulfilled, (state, { payload }) => {
        try {
          let cost_of_goods_sold = [] as any;
          let actual_food = 0 as any;
          let ideal_food = 0 as any;
          let net_sales = 0 as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            actual_food = Number(actual_food) + Number(item?.food ?? 0);
            ideal_food = Number(ideal_food) + Number(item?.ifc ?? 0);
            net_sales = Number(net_sales) + Number(item?.net_sales ?? 0);

            cost_of_goods_sold.push({
              Date:
                moment(new Date(item?.system_date)).format("DD.MMM.YYYY") ||
                "Not Available",
              "Store Name": item?.name || "Not Available",
              "Actual Food Cost":
                Number(item?.food) === 0
                  ? "0.00"
                  : Number(!isNaN(item?.food))
                  ? number_format_without_currency(Number(item?.food ?? 0.0))
                  : "0.00",
              "Actual Food %":
                Number(item?.actual_food_per) === 0
                  ? "0.00 %"
                  : Number(!isNaN(item?.actual_food_per))
                  ? number_format_without_currency(
                      Number(item?.actual_food_per ?? 0.0)
                    ) + " %"
                  : "0.00 %",
              "Ideal Food Cost":
                Number(item?.ifc) === 0
                  ? "0.00"
                  : Number(!isNaN(item?.ifc))
                  ? number_format_without_currency(Number(item?.ifc ?? 0.0))
                  : "0.00",
              "Ideal Food %":
                Number(item?.ideal_food_per) === 0
                  ? "0.00 %"
                  : Number(!isNaN(item?.ideal_food_per))
                  ? number_format_without_currency(
                      Number(item?.ideal_food_per ?? 0.0)
                    ) + " %"
                  : "0.00 %",
              "Actual & Ideal Food Variance %":
                Number(item?.actual_ideal_food_variance_per) === 0
                  ? "0.00 %"
                  : Number(!isNaN(item?.actual_ideal_food_variance_per))
                  ? number_format_without_currency(
                      Number(item?.actual_ideal_food_variance_per ?? 0.0)
                    ) + " %"
                  : "0.00 %",
            });
          }

          cost_of_goods_sold.push({
            Date: "SUMMARY",
            "Store Name": "Not Available",
            "Actual Food Cost": Number(!isNaN(actual_food))
              ? number_format_without_currency(Number(actual_food))
              : "0.00",
            "Actual Food %": Number(!isNaN(actual_food / net_sales))
              ? number_format_without_currency(
                  Number(actual_food / net_sales)
                ) + " %"
              : "0.00 %",
            "Ideal Food Cost": Number(!isNaN(ideal_food))
              ? number_format_without_currency(Number(ideal_food))
              : "0.00",
            "Ideal Food %": Number(!isNaN(ideal_food / net_sales))
              ? number_format_without_currency(Number(ideal_food / net_sales)) +
                " %"
              : "0.00 %",
            "Actual & Ideal Food Variance %":
              Number(!isNaN(actual_food / net_sales)) &&
              Number(!isNaN(ideal_food / net_sales))
                ? number_format_without_currency(
                    Number(ideal_food / net_sales) -
                      Number(actual_food / net_sales)
                  ) + " %"
                : "0.00 %",
          });

          state.export_cost_of_goods_sold = cost_of_goods_sold;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(payment_summary_report.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(payment_summary_report.fulfilled, (state, { payload }) => {
        try {
          let payment_summary = [] as any;
          let store_payment_summary_chart = [] as any;

          let cashSales = 0 as any;
          let posSales = 0 as any;
          let onlineSales = 0 as any;
          let woltCashSales = 0 as any;
          let woltOnlineSales = 0 as any;

          let cash = [] as any;
          let pos = [] as any;
          let online = [] as any;
          let woltCash = [] as any;
          let woltOnline = [] as any;
          let labels = [] as any;

          payload?.data?.result.sort(function (a: any, b: any) {
            var keyA = new Date(a.store_code),
              keyB = new Date(b.store_code);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          if (payload?.data?.result?.length > 0) {
            for (
              let index = 0;
              index < payload?.data?.result?.length;
              index++
            ) {
              const item = payload?.data?.result[index];
              cashSales = Number(cashSales) + Number(item?.cash ?? 0);
              posSales = Number(posSales) + Number(item?.pos ?? 0);
              onlineSales = Number(onlineSales) + Number(item?.bank ?? 0);
              woltCashSales =
                Number(woltCashSales) + Number(item?.wolt_cash ?? 0);
              woltOnlineSales =
                Number(woltOnlineSales) + Number(item?.wolt_online ?? 0);

              labels.push(
                item?.store_code ? item?.store_code : "Not Available"
              );

              cash.push(Number(item?.cash).toFixed(2));
              pos.push(Number(item?.pos).toFixed(2));
              online.push(Number(item?.bank).toFixed(2));
              woltCash.push(Number(item?.wolt_cash).toFixed(2));
              woltOnline.push(Number(item?.wolt_online).toFixed(2));

              payment_summary.push([
                {
                  name: item?.store_name ?? "Not Available",
                },
                {
                  name: Number(!isNaN(item?.cash))
                    ? number_format_without_currency(Number(item?.cash ?? 0)) +
                      " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.pos))
                    ? number_format_without_currency(Number(item?.pos ?? 0)) +
                      " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.bank))
                    ? number_format_without_currency(Number(item?.bank ?? 0)) +
                      " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.wolt_cash))
                    ? number_format_without_currency(
                        Number(item?.wolt_cash ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
                {
                  name: Number(!isNaN(item?.wolt_online))
                    ? number_format_without_currency(
                        Number(item?.wolt_online ?? 0)
                      ) + " ₼"
                    : "0.00 ₼",
                  alignment: "center",
                },
              ]);
            }

            store_payment_summary_chart.push(cash);
            store_payment_summary_chart.push(pos);
            store_payment_summary_chart.push(online);
            store_payment_summary_chart.push(woltCash);
            store_payment_summary_chart.push(woltOnline);
            store_payment_summary_chart.push(labels);

            payment_summary.push([
              "SUMMARY",
              {
                name:
                  number_format_without_currency(Number(cashSales ?? 0)) + " ₼",
                alignment: "center",
              },
              {
                name:
                  number_format_without_currency(Number(posSales ?? 0)) + " ₼",
                alignment: "center",
              },
              {
                name:
                  number_format_without_currency(Number(onlineSales ?? 0)) +
                  " ₼",
                alignment: "center",
              },
              {
                name:
                  number_format_without_currency(Number(woltCashSales ?? 0)) +
                  " ₼",
                alignment: "center",
              },
              {
                name:
                  number_format_without_currency(Number(woltOnlineSales ?? 0)) +
                  " ₼",
                alignment: "center",
              },
            ]);
          }

          state.store_wise_payment_summary_chart = store_payment_summary_chart;
          state.payment_summary_data = payment_summary;
          state.payment_summary = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_payment_summary_report.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(
        export_payment_summary_report.fulfilled,
        (state, { payload }) => {
          try {
            let channel_report = [] as any;
            let channel_summary = [] as any;

            let totalCash = 0 as any;
            let totalPOS = 0 as any;
            let totalOnline = 0 as any;
            let totalWoltCash = 0 as any;
            let totalWoltOnline = 0 as any;

            let period_record = "day" as any;

            if (payload?.data?.length > 0) {
              for (let index = 0; index < payload?.data?.length; index++) {
                const item = payload?.data[index];

                totalCash = Number(totalCash) + Number(item?.cash ?? 0);
                totalPOS = Number(totalPOS) + Number(item?.pos ?? 0);
                totalOnline = Number(totalOnline) + Number(item?.bank ?? 0);
                totalWoltCash =
                  Number(totalWoltCash) + Number(item?.wolt_cash ?? 0);
                totalWoltOnline =
                  Number(totalWoltOnline) + Number(item?.wolt_online ?? 0);

                period_record = item?.week_day
                  ? "day"
                  : item?.week_number
                  ? "weeek"
                  : item?.month_number
                  ? "month"
                  : item?.year_number
                  ? "year"
                  : "day";

                channel_report.push(
                  item?.week_day
                    ? {
                        "Sales Date":
                          moment(new Date(item?.sales_date)).format(
                            "DD.MMM.YYYY"
                          ) ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        Cash: Number(!isNaN(item?.cash))
                          ? number_format_without_currency(
                              Number(item?.cash ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        POS: Number(!isNaN(item?.pos))
                          ? number_format_without_currency(
                              Number(item?.pos ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        Online:
                          Number(item?.bank) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.bank ?? 0)
                              ) + " ₼",
                        "Wolt Cash":
                          Number(item?.wolt_cash) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_cash ?? 0)
                              ) + " ₼",
                        "Wolt Online":
                          Number(item?.wolt_online) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_online ?? 0)
                              ) + " ₼",
                      }
                    : item?.week_number
                    ? {
                        "Week Number":
                          "W " + item?.week_number ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        Cash: Number(!isNaN(item?.cash))
                          ? number_format_without_currency(
                              Number(item?.cash ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        POS: Number(!isNaN(item?.pos))
                          ? number_format_without_currency(
                              Number(item?.pos ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        Online:
                          Number(item?.bank) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.bank ?? 0)
                              ) + " ₼",
                        "Wolt Cash":
                          Number(item?.wolt_cash) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_cash ?? 0)
                              ) + " ₼",
                        "Wolt Online":
                          Number(item?.wolt_online) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_online ?? 0)
                              ) + " ₼",
                      }
                    : item?.month_number
                    ? {
                        Month:
                          months[item?.month_number - 1] +
                            " " +
                            new Date().getFullYear() ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        Cash: Number(!isNaN(item?.cash))
                          ? number_format_without_currency(
                              Number(item?.cash ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        POS: Number(!isNaN(item?.pos))
                          ? number_format_without_currency(
                              Number(item?.pos ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        Online:
                          Number(item?.bank) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.bank ?? 0)
                              ) + " ₼",
                        "Wolt Cash":
                          Number(item?.wolt_cash) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_cash ?? 0)
                              ) + " ₼",
                        "Wolt Online":
                          Number(item?.wolt_online) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_online ?? 0)
                              ) + " ₼",
                      }
                    : item?.year_number
                    ? {
                        Year: item?.year_number ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        Cash: Number(!isNaN(item?.cash))
                          ? number_format_without_currency(
                              Number(item?.cash ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        POS: Number(!isNaN(item?.pos))
                          ? number_format_without_currency(
                              Number(item?.pos ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        Online:
                          Number(item?.bank) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.bank ?? 0)
                              ) + " ₼",
                        "Wolt Cash":
                          Number(item?.wolt_cash) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_cash ?? 0)
                              ) + " ₼",
                        "Wolt Online":
                          Number(item?.wolt_online) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_online ?? 0)
                              ) + " ₼",
                      }
                    : {
                        "Sales Date":
                          moment(new Date(item?.sales_date)).format(
                            "DD.MMM.YYYY"
                          ) ?? "Not Available",
                        "Store Name": item?.name ?? "Not Available",
                        Cash: Number(!isNaN(item?.cash))
                          ? number_format_without_currency(
                              Number(item?.cash ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        POS: Number(!isNaN(item?.pos))
                          ? number_format_without_currency(
                              Number(item?.pos ?? 0)
                            ) + " ₼"
                          : "0.00 ₼",
                        Online:
                          Number(item?.bank) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.bank ?? 0)
                              ) + " ₼",
                        "Wolt Cash":
                          Number(item?.wolt_cash) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_cash ?? 0)
                              ) + " ₼",
                        "Wolt Online":
                          Number(item?.wolt_online) === 0
                            ? "0.00 ₼"
                            : number_format_without_currency(
                                Number(item?.wolt_online ?? 0)
                              ) + " ₼",
                      }
                );
              }

              channel_summary =
                period_record === "day"
                  ? {
                      "Sales Date": "SUMMARY",
                      "Store Name": "Not Available",
                      Cash: Number(!isNaN(totalCash))
                        ? number_format_without_currency(
                            Number(totalCash ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      POS: Number(!isNaN(totalPOS))
                        ? number_format_without_currency(
                            Number(totalPOS ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      Online:
                        Number(totalOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalOnline ?? 0)
                            ) + " ₼",
                      "Wolt Cash":
                        Number(totalWoltCash) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltCash ?? 0)
                            ) + " ₼",
                      "Wolt Online":
                        Number(totalWoltOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltOnline ?? 0)
                            ) + " ₼",
                    }
                  : period_record === "week"
                  ? {
                      "Week Number": "SUMMARY",
                      "Store Name": "Not Available",
                      Cash: Number(!isNaN(totalCash))
                        ? number_format_without_currency(
                            Number(totalCash ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      POS: Number(!isNaN(totalPOS))
                        ? number_format_without_currency(
                            Number(totalPOS ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      Online:
                        Number(totalOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalOnline ?? 0)
                            ) + " ₼",
                      "Wolt Cash":
                        Number(totalWoltCash) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltCash ?? 0)
                            ) + " ₼",
                      "Wolt Online":
                        Number(totalWoltOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltOnline ?? 0)
                            ) + " ₼",
                    }
                  : period_record === "month"
                  ? {
                      Month: "SUMMARY",
                      "Store Name": "Not Available",
                      Cash: Number(!isNaN(totalCash))
                        ? number_format_without_currency(
                            Number(totalCash ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      POS: Number(!isNaN(totalPOS))
                        ? number_format_without_currency(
                            Number(totalPOS ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      Online:
                        Number(totalOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalOnline ?? 0)
                            ) + " ₼",
                      "Wolt Cash":
                        Number(totalWoltCash) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltCash ?? 0)
                            ) + " ₼",
                      "Wolt Online":
                        Number(totalWoltOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltOnline ?? 0)
                            ) + " ₼",
                    }
                  : period_record === "year"
                  ? {
                      Year: "SUMMARY",
                      "Store Name": "Not Available",
                      Cash: Number(!isNaN(totalCash))
                        ? number_format_without_currency(
                            Number(totalCash ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      POS: Number(!isNaN(totalPOS))
                        ? number_format_without_currency(
                            Number(totalPOS ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      Online:
                        Number(totalOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalOnline ?? 0)
                            ) + " ₼",
                      "Wolt Cash":
                        Number(totalWoltCash) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltCash ?? 0)
                            ) + " ₼",
                      "Wolt Online":
                        Number(totalWoltOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltOnline ?? 0)
                            ) + " ₼",
                    }
                  : {
                      "Week Number": "SUMMARY",
                      "Store Name": "Not Available",
                      Cash: Number(!isNaN(totalCash))
                        ? number_format_without_currency(
                            Number(totalCash ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      POS: Number(!isNaN(totalPOS))
                        ? number_format_without_currency(
                            Number(totalPOS ?? 0)
                          ) + " ₼"
                        : "0.00 ₼",
                      Online:
                        Number(totalOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalOnline ?? 0)
                            ) + " ₼",
                      "Wolt Cash":
                        Number(totalWoltCash) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltCash ?? 0)
                            ) + " ₼",
                      "Wolt Online":
                        Number(totalWoltOnline) === 0
                          ? "0.00 ₼"
                          : number_format_without_currency(
                              Number(totalWoltOnline ?? 0)
                            ) + " ₼",
                    };

              channel_report.push(channel_summary);
            }

            state.export_payment_summary = channel_report;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      .addCase(
        consolidate_pulse_inventory_report.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        consolidate_pulse_inventory_report.fulfilled,
        (state, { payload }) => {
          try {
            let consolidate_pulse_inv = [] as any;

            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data[index];

              consolidate_pulse_inv.push([
                {
                  name: item?.item_name
                    ? item?.item_name || "Not Available"
                    : "Not Available",
                  style: {
                    position: "sticky",
                    left: 0,
                    backgroundColor: null,
                  },
                },
                {
                  name: item?.pulse_item_group_name
                    ? item?.pulse_item_group_name || "Not Available"
                    : "Not Available",
                  alignment: "left",
                },
                {
                  name: item?.default_unit_name
                    ? item?.default_unit_name || "Not Available"
                    : "Not Available",
                  alignment: "center",
                },
                {
                  name:
                    Number(item?.price) === 0
                      ? "0.00 ₼"
                      : number_format_without_currency(
                          Number(item?.price ?? 0)
                        ) + " ₼",
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.beginning_portion_qty ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.delivered_portion_qty ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.total_available_balance ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.ending_portion_qty ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.actual_usage ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.ideal_portion_qty ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name: number_format_without_currency(
                    Number(item?.actual_ideal_varains ?? 0)
                  ),
                  alignment: "center",
                },
                {
                  name:
                    Number(item?.actual_usage_amount) === 0
                      ? "0.00 ₼"
                      : number_format_without_currency(
                          Number(item?.actual_usage_amount ?? 0)
                        ) + " ₼",
                  alignment: "center",
                },
                {
                  name:
                    Number(item?.actual_ideal_varains_amount) === 0
                      ? "0.00 ₼"
                      : number_format_without_currency(
                          Number(item?.actual_ideal_varains_amount ?? 0)
                        ) + " ₼",
                  alignment: "center",
                },
                {
                  name:
                    Number(item?.actual_vs_ideal_per) === 0
                      ? "0.00 %"
                      : Number(!isNaN(item?.actual_vs_ideal_per))
                      ? number_format_without_currency(
                          Number(item?.actual_vs_ideal_per ?? 0.0)
                        ) + " %"
                      : "0.00 %",
                  alignment: "center",
                },
              ]);
            }

            state.consolidate_pulse_inventory_data = consolidate_pulse_inv;
            state.consolidate_pulse_inventory = payload?.data;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      .addCase(
        export_consolidate_pulse_inventory_report.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        export_consolidate_pulse_inventory_report.fulfilled,
        (state, { payload }) => {
          try {
            let consolidate_pulse_inv = [] as any;

            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data[index];

              consolidate_pulse_inv.push({
                Item: item?.item_name || "Not Available" || "Not Available",
                "Pulse Category":
                  item?.pulse_item_group_name || "Not Available",
                "Unit of Measurement": item?.default_unit_name,
                "Unit of Amount":
                  Number(item?.price) === 0
                    ? "0.00"
                    : number_format_without_currency(Number(item?.price ?? 0)),
                "Opening Balance": number_format_without_currency(
                  Number(item?.beginning_portion_qty ?? 0)
                ),
                "Sell / Buy": number_format_without_currency(
                  Number(item?.delivered_portion_qty ?? 0)
                ),
                "Total Available Balance": number_format_without_currency(
                  Number(item?.total_available_balance ?? 0)
                ),
                "Closing Balance": number_format_without_currency(
                  Number(item?.ending_portion_qty ?? 0)
                ),
                "Actual Usage": number_format_without_currency(
                  Number(item?.actual_usage ?? 0)
                ),
                "Ideal Usage": number_format_without_currency(
                  Number(item?.ideal_portion_qty ?? 0)
                ),
                "Actual & Ideal Usage Variance": number_format_without_currency(
                  Number(item?.actual_ideal_varains ?? 0)
                ),
                "Actual Usage Amount":
                  Number(item?.actual_usage_amount) === 0
                    ? "0.00"
                    : number_format_without_currency(
                        Number(item?.actual_usage_amount ?? 0)
                      ),
                "Actual & Ideal Variance Amount":
                  Number(item?.actual_ideal_varains_amount) === 0
                    ? "0.00"
                    : number_format_without_currency(
                        Number(item?.actual_ideal_varains_amount ?? 0)
                      ),
                "Usage Difference %":
                  Number(item?.actual_vs_ideal_per) === 0
                    ? "0.00 %"
                    : Number(!isNaN(item?.actual_vs_ideal_per))
                    ? number_format_without_currency(
                        Number(item?.actual_vs_ideal_per ?? 0.0)
                      ) + " %"
                    : "0.00 %",
              });
            }

            state.export_consolidate_pulse_inventory = consolidate_pulse_inv;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      );
  },
});

export const { handle_clear_form } = sales_slicer.actions;

export default sales_slicer.reducer;
