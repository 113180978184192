import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  StyledEngineProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Circle, Close, Search } from "@mui/icons-material";
import classNames from "classnames";
import { CheckIcon } from "ui-components/CheckboxIcon";
import InputField from "ui-components/TextFeild";
import DateRangeFilter from "ui-components/DateRangeFilter";
import SelectField from "ui-components/SelectField";
import Button from "ui-components/Button";
import moment from "moment";
import { set_localize_content } from "utils/helper";
import { datatable_translation } from "translation";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLessDouble";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#d2112b",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#d2112b",
  },
}));

function ItemTable(props: any) {
  const {
    classes,
    show_header,
    searchEnable,
    searchSize,
    rows,
    columns,
    enablePagination,
    current_count,
    total_records,
    table_width,
    min_width,
    radioButton,
    checkBox,
    ariaLabel,
    count,
    onRowClick,
    filterByType,
    filterByTypeOptions,
    btn_label,
    btn_disable,
    show_button,
    onButtonClick,
    date_range,
    show_button2,
    btn2_label,
    btn2_disable,
    onButton2Click,
    isSelectAll,
    hide_sn,
    CustomHeader,
    revert_header_color,
    sticky_header,
    show_row_count,
    onButton3Click,
    btn3_label,
    btn3_disable,
    show_button3,
    sortable,
    tooltip,
    table_style,
    table_height,
  } = props as any;

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      value: "", // search value store in this state

      pageNumber: 1,
      pageLimit: 10,

      rows: props.rows,

      start_date: null,
      end_date: null,
      filter_type: "all_time",

      selectedRowIndex: null,

      selectedRows: props.selectedData || [],

      is_checked_all: false,
      columns: props.columns,
    }
  );

  React.useEffect(() => {
    setState({
      rows: props.rows ?? [],
      start_date: moment(new Date(props?.start_date)).format("YYYY-MM-DD"),
      end_date: moment(new Date(props?.end_date)).format("YYYY-MM-DD"),
    });
    // eslint-disable-next-line
  }, [JSON.stringify(props.rows)]);

  React.useEffect(() => {
    if (props.selectedRow) {
      setState({
        selectedRowIndex: props.selectedRow?.index,
      });
    }
    // eslint-disable-next-line
  }, [props.selectedRow]);

  const onClickRow = (event: any, row: any, index: any) => {
    try {
      setState({
        selectedRowIndex: row[0],
      });
      onRowClick(event, row, index);
    } catch (error) {}
  };

  const handleChangePage = (page: any) => {
    try {
      setState({
        pageNumber: page,
      });
      props.handleUpdateRecords(
        state.start_date as any,
        state.end_date as any,
        page as any,
        state.pageLimit as any,
        state.value as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeLimit = (event: any) => {
    try {
      setState({
        pageLimit: event.target.value,
      });
      props.handleUpdateRecords(
        state.start_date as any,
        state.end_date as any,
        state.pageNumber as any,
        event.target.value as any,
        state.value as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onRowCheck = (event: any, row: any, index: any) => {
    try {
      let arr = state.selectedRows;
      const isPresent = state.selectedRows?.findIndex(
        (item: any) => item.id === row[0]
      );
      if (isPresent < 0) {
        arr.push({ id: row[0], row, index });
        setState({ selectedRows: arr });
      } else {
        arr.splice(isPresent, 1);
        setState({ selectedRows: arr });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckFromHead = (event: any, values: any) => {
    try {
      let arr = [];
      if (event.target.checked) {
        for (let index = 0; index < values.length; index++) {
          const _row = values[index];
          arr.push({ id: _row[0], _row, index });
        }
      } else {
        arr = [];
      }
      setState({ is_checked_all: event.target.checked, selectedRows: arr });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeDateRange = ({ start_date, end_date }: any): void => {
    try {
      setState({
        start_date: start_date,
        end_date: end_date,
      });

      props.handleUpdateRecords(
        start_date as any,
        end_date as any,
        state.pageNumber as any,
        state.pageLimit as any,
        state.value as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  const sortDataByColumn = (seq: number, key: string, index: number) => {
    let { columns } = state;
    let obj = { ...columns[index], sequence: seq };
    columns[index] = obj;
    setState({
      columns,
    });

    if (props.updateSortRow) {
      props.updateSortRow(
        key,
        seq,
        state.pageNumber as any,
        state.pageLimit as any
      );
    }
  };

  const { value, pageLimit, pageNumber, selectedRowIndex, selectedRows } =
    state;

  return (
    <StyledEngineProvider injectFirst>
      <Paper>
        {show_header ? (
          <div style={{ padding: "10px 0px 10px 0px" }}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {searchEnable ? (
                <Grid item xs={12} md={3}>
                  <InputField
                    fullWidth
                    value={value}
                    size={searchSize}
                    variant={"outlined"}
                    placeholder={set_localize_content(
                      datatable_translation?.search,
                      "en"
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {value ? (
                            <Close
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() =>
                                setState({ value: "", rows: props.rows })
                              }
                            />
                          ) : (
                            <Search style={{ color: "gray" }} />
                          )}
                        </InputAdornment>
                      ),
                      className: classNames(
                        classes?.input_feild,
                        "Roboto-Medium"
                      ),
                    }}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      const filtered_rows = props?.rows?.filter((item: any) =>
                        item?.some((_row: any) =>
                          _row?.name
                            ? `${_row.name}`
                                ?.toLowerCase()
                                ?.indexOf(value?.toLowerCase()) !== -1
                            : `${_row}`
                                ?.toLowerCase()
                                ?.indexOf(value?.toLowerCase()) !== -1
                        )
                      );
                      setState({
                        value,
                        rows: value ? filtered_rows : props.rows,
                      });
                    }}
                    inputProps={{
                      className: classNames(
                        classes?.input_feild,
                        "Roboto-Medium"
                      ),
                    }}
                    classes={classes}
                    style={{ paddingLeft: 10 }}
                  />
                </Grid>
              ) : null}

              {date_range ? (
                <Grid sm={12} md={3} item>
                  <DateRangeFilter
                    disableClearable={true}
                    placeholder={set_localize_content(
                      datatable_translation?.duration,
                      "en"
                    )}
                    onChange={handleChangeDateRange}
                  />
                </Grid>
              ) : null}

              {filterByType && (
                <Grid sm={12} md={3} item>
                  <SelectField
                    options={filterByTypeOptions || []}
                    value={filterByTypeOptions[0]}
                    placeholder={set_localize_content(
                      datatable_translation?.duration,
                      "en"
                    )}
                    handleSelect={(event, name, value) =>
                      console.log(event, name, value)
                    }
                    disableClearable={true}
                  />
                </Grid>
              )}

              {show_button && (
                <Grid
                  xs={12}
                  md={
                    date_range && filterByType
                      ? 3
                      : (date_range && !filterByType) ||
                        (!date_range && filterByType)
                      ? 6
                      : !searchEnable && !date_range && !filterByType
                      ? 12
                      : 9
                  }
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingRight: 10,
                  }}
                >
                  {show_button2 && (
                    <Button
                      onClick={onButton2Click}
                      value={btn2_label}
                      disable={btn2_disable}
                      classesNames={classNames(
                        classes.button_width,
                        btn2_disable
                          ? classes.disabled_button
                          : classes.contained_button
                      )}
                      style={{ marginRight: 20 }}
                    />
                  )}

                  {show_button3 && (
                    <Button
                      onClick={onButton3Click}
                      value={btn3_label}
                      disable={btn3_disable}
                      classesNames={classNames(
                        classes.button_width,
                        btn3_disable
                          ? classes.disabled_button
                          : classes.contained_button
                      )}
                      style={{ marginRight: 20 }}
                    />
                  )}

                  <Button
                    onClick={onButtonClick}
                    value={btn_label}
                    disable={btn_disable}
                    classesNames={classNames(
                      classes.button_width,
                      btn_disable
                        ? classes.disabled_button
                        : classes.contained_button
                    )}
                  />
                </Grid>
              )}
            </Grid>

            {show_row_count ? (
              <>
                <br />

                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "flex-start",
                    marginLeft: 10,
                  }}
                >
                  <Typography
                    variant="h4"
                    className={classNames(classes?.typography_blue_heading)}
                  >
                    {set_localize_content(datatable_translation?.total, "en")}{" "}
                    {rows[rows.length - 1]?.includes("SUMMARY")
                      ? rows.length - 1
                      : rows.length || 0}{" "}
                    {set_localize_content(datatable_translation?.records, "en")}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </div>
        ) : (
          CustomHeader()
        )}

        <TableContainer
          style={
            table_style
              ? { height: table_height, marginTop: 10 }
              : rows?.length > 10
              ? { height: 500, marginTop: 10 }
              : {}
          }
          className="table-scrollbar"
        >
          <Table
            stickyHeader={sticky_header}
            style={
              rows?.length > 0
                ? {
                    width: table_width,
                    margin: 0,
                    marginBottom: 15,
                  }
                : { width: table_width, margin: 0 }
            }
            sx={{ minWidth: min_width }}
            aria-label={ariaLabel}
            id={ariaLabel}
            className="table-scrollbar"
          >
            <TableHead
              className={
                revert_header_color
                  ? classes?.background_light_gray
                  : classes?.background_blue
              }
            >
              <TableRow>
                {radioButton || checkBox || isSelectAll ? (
                  <TableCell
                    align={"left"}
                    className={classNames(
                      classes?.normal_text,
                      isSelectAll ? "padding-0" : "padding-10"
                    )}
                    style={{ width: 33 }}
                  >
                    {isSelectAll && (
                      <Checkbox
                        style={{ padding: "0px 0px 0px 10px" }}
                        checked={state.is_checked_all}
                        onChange={(e) => handleCheckFromHead(e, rows)}
                        className={classNames(classes?.text_6)}
                        checkedIcon={
                          <CheckIcon classes={classNames(classes.check_icon)} />
                        }
                      />
                    )}
                  </TableCell>
                ) : null}
                {hide_sn && (
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginRight: 10 }}>
                      {set_localize_content(
                        datatable_translation?.serial_number,
                        "en"
                      )}
                    </span>
                  </TableCell>
                )}
                {state?.columns.length > 0 &&
                  state?.columns.map((col: any, index: any) => (
                    <TableCell
                      className={classNames(
                        classes?.table_head,
                        "padding-10",
                        "RobotoCondensed-Medium"
                      )}
                      key={index}
                      align={col.alignment}
                      sortDirection={col.sorted_key}
                      onClick={() => {
                        if (!sortable) return;
                        switch (col.sequence) {
                          case 0:
                            sortDataByColumn(1, col.sorted_key, index);
                            break;
                          case 1:
                            sortDataByColumn(0, col.sorted_key, index);
                            break;
                        }
                      }}
                      style={col?.style ? col?.style : null}
                    >
                      <span
                        style={{
                          marginRight: col.alignment === "center" ? 0 : 10,
                        }}
                      >
                        {col?.name}
                        {sortable && !col?.show_ ? (
                          col.sequence === 0 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              style={{
                                height: 14,
                                stroke: "#ffffff",
                                marginLeft: 5,
                                strokeWidth: 1,
                                fill: "#ffffff",
                              }}
                            >
                              <title>Sort Desending</title>
                              <path d="M17 10c-.3 0-.5-.1-.7-.3l-5-5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5 5c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" />
                              <path d="M7 10c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-5 5c-.2.2-.4.3-.7.3z" />
                              <path d="M12 21c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1s1 .4 1 1v16c0 .6-.4 1-1 1z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              style={{
                                height: 14,
                                stroke: "#ffffff",
                                marginLeft: 5,
                                strokeWidth: 1,
                                fill: "#ffffff",
                              }}
                            >
                              <title>Sort Ascending</title>
                              <path d="M11.293,22.707a1,1,0,0,0,1.414,0l6-6a1,1,0,0,0-1.414-1.414L13,19.586V2a1,1,0,0,0-2,0V19.586L6.707,15.293a1,1,0,0,0-1.414,1.414Z" />
                            </svg>
                          )
                        ) : null}
                      </span>
                    </TableCell>
                  ))}
                {columns.length === 0 ? (
                  <TableCell className={"padding-10"} align={"center"}>
                    {set_localize_content(
                      datatable_translation?.no_columns_found,
                      "en"
                    )}
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>

            {state.rows?.length > 0 ? (
              <TableBody>
                {state.rows?.map((row: any, index: any) => (
                  <TableRow
                    onClick={
                      checkBox
                        ? (event) => onRowCheck(event, row, index)
                        : (event) => onClickRow(event, row, index)
                    }
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#eeeeee",
                    }}
                    className={classNames(
                      radioButton
                        ? row?.findIndex((a: any) =>
                            a?.name
                              ? a?.name === selectedRowIndex
                              : a === selectedRowIndex
                          ) > -1
                          ? classes.selected_row
                          : classes.unselected_row
                        : classes.unselected_row
                    )}
                  >
                    {checkBox === true ? (
                      <TableCell
                        key={index}
                        align={"left"}
                        className={classNames(
                          classes?.normal_text,
                          "padding-0"
                        )}
                      >
                        <Checkbox
                          checked={
                            selectedRows?.findIndex(
                              (i: any) => i.id === row[0] || row[0]?.name
                            ) < 0
                              ? false
                              : true
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                            onRowCheck(event, row, index);
                          }}
                          className={classNames(classes?.text_6)}
                          checkedIcon={
                            <CheckIcon
                              classes={classNames(classes.check_icon)}
                            />
                          }
                          style={{ padding: "0px 0px 0px 10px" }}
                        />
                      </TableCell>
                    ) : null}

                    {radioButton === true ? (
                      <TableCell
                        key={index}
                        align={"left"}
                        style={{
                          padding: "5px 0px 0px 8px",
                        }}
                      >
                        {row?.findIndex((a: any) =>
                          a?.name
                            ? a?.name === selectedRowIndex
                            : a === selectedRowIndex
                        ) > -1 ? (
                          <CheckIcon classes={classNames(classes.check_icon)} />
                        ) : null}
                      </TableCell>
                    ) : null}

                    {hide_sn && (
                      <TableCell
                        className={classNames(
                          classes?.normal_text,
                          "padding-10",
                          "Roboto-Medium"
                        )}
                        key={"0.001"}
                        align={"left"}
                      >
                        {index + 1}
                      </TableCell>
                    )}

                    {row.map(
                      (item: any, cellIndex: any) =>
                        !item?.hidden &&
                        (tooltip && item?.style?.backgroundColor === "green" ? (
                          <BootstrapTooltip
                            title={
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                  fontWeight: 700,
                                  fontSize: 12,
                                }}
                              >
                                {state?.rows?.[index]?.[0]}
                              </div>
                            }
                            arrow
                          >
                            <TableCell
                              rowSpan={item?.rowSpan ?? 1}
                              title={item?.hover ? item?.hover : null}
                              className={classNames(
                                classes?.normal_text,
                                "padding-10",
                                "Roboto-Medium"
                              )}
                              key={cellIndex}
                              style={item?.style ? item?.style : null}
                              align={item?.alignment ? item?.alignment : "left"}
                            >
                              {item?.is_bool ? (
                                <Circle
                                  style={{
                                    color: item?.color || item?.style?.color,
                                    fontSize: 10,
                                    marginRight: 5,
                                  }}
                                />
                              ) : item?.is_priority === "high" ? (
                                <KeyboardDoubleArrowUpIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : item?.is_priority === "low" ? (
                                <KeyboardDoubleArrowDownIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : item?.is_priority === "normal" ? (
                                <UnfoldLessIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : null}
                              {item?.name ? item?.name : item}
                            </TableCell>
                          </BootstrapTooltip>
                        ) : tooltip && cellIndex !== 0 ? (
                          <TableCell
                            rowSpan={item?.rowSpan ?? 1}
                            title={item?.hover ? item?.hover : null}
                            className={classNames(
                              classes?.normal_text,
                              "padding-10",
                              "Roboto-Medium"
                            )}
                            key={cellIndex}
                            style={item?.style ? item?.style : null}
                            align={item?.alignment ? item?.alignment : "left"}
                          >
                            {item?.is_bool ? (
                              <Circle
                                style={{
                                  color: item?.color || item?.style?.color,
                                  fontSize: 10,
                                  marginRight: 5,
                                }}
                              />
                            ) : item?.is_priority === "high" ? (
                              <KeyboardDoubleArrowUpIcon
                                style={{
                                  fontSize: 15,
                                  marginRight: 5,
                                  color: item?.color || item?.style?.color,
                                }}
                              />
                            ) : item?.is_priority === "low" ? (
                              <KeyboardDoubleArrowDownIcon
                                style={{
                                  fontSize: 15,
                                  marginRight: 5,
                                  color: item?.color || item?.style?.color,
                                }}
                              />
                            ) : item?.is_priority === "normal" ? (
                              <UnfoldLessIcon
                                style={{
                                  fontSize: 15,
                                  marginRight: 5,
                                  color: item?.color || item?.style?.color,
                                }}
                              />
                            ) : null}
                            {item?.name ? item?.name : item}
                          </TableCell>
                        ) : !tooltip && item?.hover ? (
                          <BootstrapTooltip title={item?.hover} arrow>
                            <TableCell
                              rowSpan={item?.rowSpan ?? 1}
                              className={classNames(
                                classes?.normal_text,
                                "padding-10",
                                "Roboto-Medium"
                              )}
                              key={cellIndex}
                              style={item?.style ? item?.style : null}
                              align={item?.alignment ? item?.alignment : "left"}
                            >
                              {item?.is_bool ? (
                                <Circle
                                  style={{
                                    color: item?.color || item?.style?.color,
                                    fontSize: 10,
                                    marginRight: 5,
                                  }}
                                />
                              ) : item?.is_priority === "high" ? (
                                <KeyboardDoubleArrowUpIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : item?.is_priority === "low" ? (
                                <KeyboardDoubleArrowDownIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : item?.is_priority === "normal" ? (
                                <UnfoldLessIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : null}
                              {item?.name ? item?.name : item}
                            </TableCell>
                          </BootstrapTooltip>
                        ) : (
                          !tooltip && (
                            <TableCell
                              rowSpan={item?.rowSpan ?? 1}
                              title={item?.hover ? item?.hover : null}
                              className={classNames(
                                classes?.normal_text,
                                "padding-10",
                                "Roboto-Medium"
                              )}
                              key={cellIndex}
                              style={item?.style ? item?.style : null}
                              align={item?.alignment ? item?.alignment : "left"}
                            >
                              {item?.is_bool ? (
                                <Circle
                                  style={{
                                    color: item?.color || item?.style?.color,
                                    fontSize: 10,
                                    marginRight: 5,
                                  }}
                                />
                              ) : item?.is_priority === "high" ? (
                                <KeyboardDoubleArrowUpIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : item?.is_priority === "low" ? (
                                <KeyboardDoubleArrowDownIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : item?.is_priority === "normal" ? (
                                <UnfoldLessIcon
                                  style={{
                                    fontSize: 15,
                                    marginRight: 5,
                                    color: item?.color || item?.style?.color,
                                  }}
                                />
                              ) : null}
                              {item?.name ? item?.name : item}
                            </TableCell>
                          )
                        ))
                    )}
                  </TableRow>
                ))}
              </TableBody>
            ) : revert_header_color ? (
              <TableBody>
                <TableRow className={classNames(classes.unselected_row)}>
                  <TableCell
                    colSpan={columns?.length + 1}
                    align={"center"}
                    style={{
                      padding: "15px 0px",
                    }}
                    className={classNames(
                      classes?.normal_text,
                      "Roboto-Medium"
                    )}
                  >
                    {set_localize_content(
                      datatable_translation?.no_records_found,
                      "en"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : state.rows?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    className={"no-records-found"}
                    style={{
                      backgroundColor: "#fff",
                      color: "black",
                      fontFamily: "BebasNeue-Bold",
                      fontSize: 24,
                      textAlign: "center",
                    }}
                    colSpan={columns?.length}
                  >
                    {set_localize_content(
                      datatable_translation?.no_records_found,
                      "en"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>

        {enablePagination && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: 10,
            }}
          >
            <div
              className={classNames(
                classes.pagination_per_page,
                "Roboto-Regular"
              )}
            >
              <Select
                disabled={rows?.length < 1 ? true : false}
                size="small"
                variant="standard"
                disableUnderline
                style={{ padding: 0, margin: 0, width: 100 }}
                className={classNames(
                  classes.color_secondary,
                  "Roboto-Regular"
                )}
                value={pageLimit}
                onChange={handleChangeLimit}
                sx={{
                  "& .MuiSelect-select": {
                    padding: 0,
                    color: "#0078ac",
                    fontSize: 14,
                  },
                  "& svg": {
                    fill: "#0078ac",
                    opacity: 0.8,
                  },
                }}
              >
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={1}
                >
                  {set_localize_content(
                    datatable_translation?.one_per_page,
                    "en"
                  )}
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={10}
                >
                  {set_localize_content(
                    datatable_translation?.ten_per_page,
                    "en"
                  )}
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={25}
                >
                  {set_localize_content(
                    datatable_translation?.twenty_five_per_page,
                    "en"
                  )}
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={50}
                >
                  {set_localize_content(
                    datatable_translation?.fifty_per_page,
                    "en"
                  )}
                </MenuItem>
                <MenuItem
                  className={classNames(
                    classes.normal_text,
                    "Roboto-Regular",
                    "padding-10"
                  )}
                  value={100}
                >
                  {set_localize_content(
                    datatable_translation?.hundred_per_page,
                    "en"
                  )}
                </MenuItem>
              </Select>
            </div>
            <Pagination
              count={count ?? 1}
              page={pageNumber}
              onChange={(event, page) => handleChangePage(page)}
              color="primary"
              size={"medium"}
              shape="rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={classNames(classes.pagination_container)}
              variant="outlined"
              showFirstButton
              showLastButton
            />
            &emsp;
            <span className={classNames(classes.normal_text, "Roboto-Regular")}>
              {`${
                rows.length < 1
                  ? set_localize_content(
                      datatable_translation?.no_records_found,
                      "en"
                    )
                  : `${set_localize_content(
                      datatable_translation?.showing,
                      "en"
                    )} ${Number(current_count || 0)} ${set_localize_content(
                      datatable_translation?.of,
                      "en"
                    )} ${Number(total_records || 0)} ${set_localize_content(
                      datatable_translation?.records,
                      "en"
                    )}`
              }`}
            </span>
          </div>
        )}
      </Paper>
    </StyledEngineProvider>
  );
}

ItemTable.defaultProps = {
  tooltip: false,
  ariaLabel: "Table",
  table_width: "max-content",
  selectedRowColor: "",
  searchSize: "small",
  count: 1,
  caption: false,
  enablePagination: false,
  radioButton: false,
  searchEnable: true,
  checkBox: false,
  columns: [],
  rows: [],
  filterByTypeOptions: [],
  show_row_count: true,
  show_button3: false,
  btn3_label: "",
  btn3_disable: false,
  selectedRow: { index: null },
  CaptionBody: () => <caption></caption>,
  onCheckChange: () => {},
  onRowClick: () => {},
  header_color: false,
  _height: 80,
  search_by_date: false,
  updateRecord: () => {},
  changePagination: () => {},
  min_width: "1350px",
  show_header: true,
  show_button: false,
  onButtonClick: () => {},
  btn_label: "",
  trim_id: false,
  btn_disable: false,
  sortable: false,
  date_range: true,
  filterByType: true,
  dateFormat: "DD-MMM-YYYY",
  classes: {},
  show_button2: false,
  btn2_label: "",
  btn2_disable: false,
  onButton2Click: () => {},
  CustomHeader: () => <></>,
  isSelectAll: false,
  hide_sn: true,
  revert_header_color: false,
  handleUpdateRecords: () => {},
  selectedData: [],
  sortDirection: "",
};

ItemTable.propTypes = {
  tooltip: PropTypes.bool,
  classes: PropTypes.any,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  caption: PropTypes.bool,
  enablePagination: PropTypes.bool,
  radioButton: PropTypes.bool,
  searchEnable: PropTypes.bool,
  checkBox: PropTypes.bool,
  CaptionBody: PropTypes.func,
  onCheckChange: PropTypes.func,
  onRowClick: PropTypes.func,
  count: PropTypes.number,
  ariaLabel: PropTypes.string,
  searchSize: PropTypes.string,
  table_width: PropTypes.string,
  header_color: PropTypes.bool,
  search_by_date: PropTypes.bool,
  show_header: PropTypes.bool,
  updateRecord: PropTypes.func,
  show_button: PropTypes.bool,
  onButtonClick: PropTypes.func,
  changePagination: PropTypes.func,
  btn_label: PropTypes.string,
  btn_disable: PropTypes.bool,
  sortable: PropTypes.bool,
  dateFormat: PropTypes.string,
  previous_count: PropTypes.any,
  current_count: PropTypes.any,
  total_records: PropTypes.any,
  min_width: PropTypes.any,
  selectedRow: PropTypes.any,
  filterByTypeOptions: PropTypes.any,
  filterByType: PropTypes.bool,
  date_range: PropTypes.bool,
  show_button2: PropTypes.bool,
  btn2_label: PropTypes.string,
  btn2_disable: PropTypes.bool,
  onButton2Click: PropTypes.func,
  isSelectAll: PropTypes.bool,
  show_button3: PropTypes.bool,
  btn3_label: PropTypes.string,
  btn3_disable: PropTypes.bool,
  onButton3Click: PropTypes.func,
  hide_sn: PropTypes.bool,
  CustomHeader: PropTypes.any,
  revert_header_color: PropTypes.bool,
  show_row_count: PropTypes.bool,
  handleUpdateRecords: PropTypes.func,
  sticky_header: PropTypes.bool,
  selectedData: PropTypes.any,
  btn_classes: PropTypes.any,
  btn_classes2: PropTypes.any,
  sortDirection: PropTypes.string,
  updateSortRow: PropTypes.func,
  table_style: PropTypes.bool,
  table_height: PropTypes.number,
};

export default ItemTable;
