import PropTypes from "prop-types";
import { Dialog, DialogTitle, Paper } from "@mui/material";
import Draggable from "react-draggable";
import { Close } from "@mui/icons-material";
import classNames from "classnames";

function PaperComponent(props: any) {
  return <Paper {...props} />;
}

function DraggablePaperComponent(props: any) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function DraggableDialog(props: any) {
  const {
    handleClose,
    open,
    maxWidth,
    classes,
    dialog_title,
    draggable,
    dialogBody,
    dialogFooter,
  } = props;
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={maxWidth}
        PaperComponent={draggable ? DraggablePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
        disableScrollLock
      >
        <DialogTitle
          className={classes?.modal_background}
          style={{
            cursor: draggable ? "move" : "auto",
          }}
        >
          <div className={classes?.display_flex_baseline}>
            <span
              style={{
                flex: 1,
              }}
              className={classNames(classes?.modal_title)}
            >
              {dialog_title}
            </span>
            <Close
              style={{ cursor: "pointer" }}
              className={classNames(classes?.modal_title)}
              onClick={handleClose}
            />
          </div>
        </DialogTitle>
        {dialogBody}
        {dialogFooter}
      </Dialog>
    </div>
  );
}

DraggableDialog.defaultProps = {
  open: false,
  maxWidth: "md",
  dialog_title: "",
  handleClose: () => {},
  classes: {},
  draggable: true,
};

DraggableDialog.propTypes = {
  maxWidth: PropTypes.string,
  dialog_title: PropTypes.string,
  classes: PropTypes.any,
  draggable: PropTypes.bool,
  dialogBody: PropTypes.any.isRequired,
  dialogFooter: PropTypes.any.isRequired,
};

export default DraggableDialog;
