import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, Checkbox, Paper, TextField } from "@mui/material";
import { default_label_colors } from "utils/constants";
import { KeyboardArrowDown } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import VerifiedIcon from "@mui/icons-material/Verified";

const icon = (
  <CheckBoxOutlineBlankIcon fontSize="small" sx={{ color: "black" }} />
);
const checkedIcon = <CheckBoxIcon fontSize="small" sx={{ color: "#0078ac" }} />;

export default function SelectField(props: any) {
  const {
    input_label,
    multiple,
    required,
    value,
    classes,
    error,
    options,
    isDisabled,
    name,
    variant,
    placeholder,
    handleSelect,
    readOnly,
    disableClearable,
    field_id,
    style,
    multi_col,
    custom_option,
    auto_height,
    _height,
    _custom_padding,
  } = props;

  return (
    <form
      style={{ width: "100%" }}
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {input_label && (
        <label
          className={classNames(
            classes.input_label_style,
            "RobotoCondensed-Regular",
            error && classes.color_red
          )}
        >
          {input_label}
          {required ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
      )}

      <Autocomplete
        disablePortal
        multiple={multiple}
        options={
          multi_col
            ? options?.map((item: any) => {
              return {
                ...item,
                label: `${item.label_2} ${item.label1}`,
                value: item?.value,
              };
            })
            : options
        }
        getOptionLabel={
          multi_col
            ? (option) =>
              value?.length > 1 && option?.label1 && option?.is_default ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  {option?.label1}
                  {option?.label1 && (
                    <VerifiedIcon
                      style={{
                        color: "#3b78ad",
                        marginLeft: 5,
                        fontSize: "larger",
                      }}
                    />
                  )}
                </span>
              ) : option?.label1 ? (
                option?.label1
              ) : (
                option?.label
              )
            : (option) => option.label
        }
        fullWidth
        disabled={isDisabled}
        placeholder={isDisabled ? "" : placeholder}
        style={
          style
            ? {
              ...style,
              background: isDisabled ? "transparent" : "transparent",
              color: default_label_colors.color_e,
            }
            : {
              background: isDisabled ? "transparent" : "transparent",
              color: default_label_colors.color_e,
            }
        }
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{ fontFamily: "Roboto-Medium", background: "white" }}
          >
            {multiple ? (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            ) : null}

            {custom_option ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontFamily: "Roboto-Medium",
                    textAlign: "left",
                    lineHeight: 2,
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {option.label1}
                </div>
                <div
                  style={{
                    fontFamily: "Roboto-Regular",
                    textAlign: "left",
                    lineHeight: 1,
                    color: "black",
                  }}
                >
                  {option.label_2}
                </div>
              </div>
            ) : (
              option.label
            )}
          </li>
        )}
        size="small"
        value={value}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              "& ul": {
                maxHeight: 200,
                "& li": {
                  fontFamily: "Roboto-Regular",
                  fontSize: 16,
                  fontWeight: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: default_label_colors.color_e,
                  borderBottom: "1px solid #E0E0E0",
                },
              },
            }}
            style={{
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
              maxHeight: 200,
              overflow: "hidden scroll",
            }}
          >
            {children}
          </Paper>
        )}
        popupIcon={
          isDisabled || readOnly ? null : (
            <KeyboardArrowDown style={{ color: default_label_colors.color_f }} />
          )
        }
        autoComplete={false}
        onChange={(event, values) => handleSelect(name, event, values)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            size="small"
            autoComplete="off"
            aria-autocomplete="none"
            placeholder={placeholder}
            error={error}
            variant={variant}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#181818",
              },
            }}
            inputProps={{
              ...params.inputProps,
              className: classNames(classes?.input_feild, "Roboto-Medium"),
              style: {
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                fontWeight: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                backgroundColor: isDisabled ? "transparent" : "transparent",
                background: isDisabled ? "transparent" : "transparent",
                color: default_label_colors.color_e,
                width: "100%",
                paddingTop: _custom_padding ? 0 : 6,
              },
              autoComplete: "off",
              "aria-autocomplete": "none",
            }}
            InputProps={{
              ...params.InputProps,
              className: classNames(classes?.input_feild, "Roboto-Medium"),
              style: {
                background: isDisabled ? "transparent" : "white",
                height: _height ? _height : auto_height ? "auto" : multiple && value?.length > 2 ? "auto" : 43,
                display: "block",
                paddingRight: 10,
              },
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              autoComplete: "off",
            }}
            style={{
              background: isDisabled ? "transparent" : "white",
            }}
          />
        )}
        readOnly={readOnly}
        disableClearable={disableClearable}
        id={field_id}
      />
    </form>
  );
}

SelectField.defaultProps = {
  input_label: "",
  value: null,
  multiple: false,
  required: false,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  handleSelect: null,
  options: [],
  isDisabled: false,
  readOnly: false,
  name: "",
  disableClearable: false,
  multi_col: false,
  custom_option: null,
  field_id: "",
  auto_height: false,
  _height: "",
  _custom_padding: false,
};

SelectField.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  disableClearable: PropTypes.bool,
  handleSelect: PropTypes.func,
  field_id: PropTypes.string,
  multi_col: PropTypes.bool,
  custom_option: PropTypes.any,
  auto_height: PropTypes.bool,
  _height: PropTypes.string,
  _custom_padding: PropTypes.bool
};
